import * as scmpAccount from '@product/scmp-account-sdk'
import get from 'lodash/get'
import { useEffect } from 'react'
import { useStore } from 'store'
import styled from 'styled-components'

import {
  CENTRALIZE_LOGIN_PROTOCOL,
  CENTRALIZE_LOGIN_HOST,
  GOOGLE_CLIENT_ID,
  GA_PROFILE_ID,
  ACCOUNT_DEFAULT_AUTH_CALLBACK_URL,
} from '../../config/app.config'

const GoogleOneTapContainer = styled.div`
  position: absolute;
  right: 0;
`

const GoogleOneTapSignIn = () => {
  const { loginState } = useStore()
  const logined = get(loginState, 'state.loginedState')
  let googleOneTapLogin

  if (typeof window !== 'undefined') {
    const path = 'login/google-oneTap/return'
    const destination = window.location.href

    const state = JSON.stringify({
      destination,
      callbackUrl: ACCOUNT_DEFAULT_AUTH_CALLBACK_URL,
      appID: '325477', // scmp app id
      gaProfileID: GA_PROFILE_ID,
      application: scmpAccount.APPLICATION.YP,
    })

    const redirectEndPoint = `${CENTRALIZE_LOGIN_PROTOCOL}://${CENTRALIZE_LOGIN_HOST}/${path}`

    if (logined !== null) {
      if (!logined) {
        googleOneTapLogin = (
          <GoogleOneTapContainer
            id="g_id_onload"
            data-client_id={GOOGLE_CLIENT_ID}
            data-login_uri={redirectEndPoint}
            data-state={state}
            data-skip_prompt_cookie="sid"
            data-prompt_parent_id="g_id_onload"
            data-cancel_on_tap_outside="false"
            className="g_id_onload"
          ></GoogleOneTapContainer>
        )
      }
    }
  }

  useEffect(() => {
    const script = document.createElement('script')
    script.src = 'https://accounts.google.com/gsi/client'
    document.body.appendChild(script)
  }, [])

  return <>{googleOneTapLogin}</>
}

export default GoogleOneTapSignIn
