import { Grid, Row, Col } from '@product/react-styled-flexboxgrid'
import Avatar from 'components/Avatar'
import Logo from 'components/Common/Logo'
import ScmpHeader from 'components/Common/Logo/ScmpHeader'
import Breadcrumb from 'components/Header/children/Breadcrumb'
import Menu from 'components/Header/children/Menu'
import NavigationItemsDepth1 from 'components/Header/children/NavigationItemsDepth1'
import NavigationItemsDepth2 from 'components/Header/children/NavigationItemsDepth2'
import EpaperButton from 'components/Header/EpaperButton'
import HeaderCommentButton from 'components/Header/HeaderCommentButton'
import SubscribeButton from 'components/Header/SubscribeButton'
import Link from 'components/Link'
import { SCMP_MAIN_SITE_DOMAIN } from 'config/app.config'
import { GENERAL } from 'config/app.config'
import filter from 'lodash/filter'
import get from 'lodash/get'
import PropTypes from 'prop-types'
import CloseIcon from 'public/assets/svg/close.svg'
import MenuIcon from 'public/assets/svg/menu.svg'
import SearchIcon from 'public/assets/svg/search.svg'
import { useCallback, useEffect, useState } from 'react'
import { useStore } from 'store'
import styled, { css, withTheme } from 'styled-components'
import { remCalc, color, fontFamily, noGutter, respondTo } from 'styles/mixins'
import { isSubscriber } from 'utils/rosetta'

const mdTo1279px = (...styles) => css`
  @media (min-width: ${GENERAL.breakpoints.md}px) and (max-width: 1279px) {
    ${css(...styles)}
  }
`

const HeaderWrapper = styled.div`
  width: 100%;
  background-color: ${(props) => props.theme.bgColor};
  padding-top: ${remCalc(20)};
  padding-bottom: ${remCalc(16)};
  ${(props) =>
    (props.toSmall && !props.theme.menuExpanded) ||
    (props.theme.sticky.desktop && !props.theme.menuExpanded) ||
    (!props.theme.isParentSection && !props.theme.menuExpanded)
      ? css`
          padding: ${remCalc(15, 0)};
        `
      : ''}

  ${(props) =>
    props.theme.sticky.desktop &&
    css`
      background-color: ${(props) => props.theme.bgColorTrans};
    `}

  ${(props) =>
    props.theme.menuExpanded
      ? css`
          background-color: ${(props) => props.theme.bgColor};
        `
      : ''}
`

const HeaderGrid = styled(Grid)`
  position: relative;
  ${respondTo.mdAndDown`
    width: auto;
    padding: ${remCalc(0, 28)};
  `}
`

const StyledNavigationItemsDepth1 = styled(NavigationItemsDepth1)`
  .item {
    padding-right: ${remCalc(40)};
    padding-bottom: ${remCalc(28)};
    font-size: ${remCalc(28)};
    line-height: ${remCalc(28)};
    letter-spacing: ${remCalc(1.6)};
    ${mdTo1279px`font-size: ${remCalc(24)};`}
    ${(props) =>
      props.theme.menuExpanded
        ? css`
            padding-bottom: ${remCalc(0)};
            .active:after {
              content: '';
              position: absolute;
              width: 0;
              height: 0;
              border-style: solid;
              bottom: ${remCalc(-64)};
              left: 50%;
              transform: translateX(-50%);
              border-width: ${remCalc(0, 12, 16, 12)};
              border-color: transparent transparent ${(props) => props.theme.menuBgColor}
                transparent;
            }
          `
        : ''}
    ${(props) =>
      (props.toSmall && !props.theme.menuExpanded) ||
      (props.theme.sticky.desktop && !props.theme.menuExpanded) ||
      (!props.theme.isParentSection && !props.theme.menuExpanded)
        ? css`
            padding-bottom: 0;
            font-size: ${remCalc(24)};
            letter-spacing: ${remCalc(1.2)};
            line-height: ${remCalc(28)};
          `
        : ''}
  }
`

const StyledNavigationItemsDepth2 = styled(NavigationItemsDepth2)`
  font-family: ${fontFamily.roboto};
  font-size: ${remCalc(16)};
  line-height: ${remCalc(19)};
  letter-spacing: ${remCalc(0.89)};
  overflow-y: auto;
  white-space: nowrap;
  &::-webkit-scrollbar {
    width: 5px;
    background: transparent;
    -webkit-tap-highlight-color: transparent;
  }
  ${(props) =>
    props.theme.isStaticPage &&
    css`
      display: none;
    `}
  .item {
    display: inline-block;
    padding-right: ${remCalc(32)};
    opacity: 0.5;
    .active,
    &:hover {
      opacity: 1;
    }
  }
`

const LogoWrap = styled(Col)`
  && {
    max-width: ${remCalc(121)};
    padding-left: 0;
    padding-right: 0;
    line-height: 0;
    width: ${remCalc(121)};
    height: ${remCalc(117)};
    margin: 0 auto;
    ${(props) =>
      (props.toSmall && !props.theme.menuExpanded) ||
      (props.theme.sticky.desktop && !props.theme.menuExpanded) ||
      (!props.theme.isParentSection && !props.theme.menuExpanded) ||
      props.theme.isStaticPage ||
      (props.theme.routerType === 'article' && !props.theme.menuExpanded)
        ? css`
            max-width: ${remCalc(89)};
            height: auto;
          `
        : ''}
  }
`

const StyledLogo = styled(Logo)`
  height: ${remCalc(115)};
  width: ${remCalc(118)};
  margin: 0 auto;

  ${(props) =>
    (props.toSmall && !props.theme.menuExpanded) ||
    (props.theme.sticky.desktop && !props.theme.menuExpanded) ||
    (!props.theme.isParentSection && !props.theme.menuExpanded) ||
    props.theme.isStaticPage ||
    (props.theme.routerType === 'article' && !props.theme.menuExpanded)
      ? css`
          width: ${remCalc(88)};
          height: ${remCalc(70)};
        `
      : ''}
`

const NavItems = styled(Col)`
  ${(props) =>
    props.theme.sticky.desktop && props.showTitle && !props.theme.menuExpanded
      ? css`
          overflow-x: hidden;
        `
      : ''}
  && {
    padding-left: ${(props) => (props.isParentSection ? remCalc(31) : remCalc(41))};
    padding-top: ${(props) => (props.isParentSection ? remCalc(40) : remCalc(40))};
    flex: 1;
    max-width: 100%;
    flex-basis: auto;
  }
  ${(props) =>
    (props.toSmall && !props.theme.menuExpanded) ||
    (props.theme.sticky.desktop && !props.theme.menuExpanded)
      ? css`
          && {
            padding-top: ${remCalc(19)};
            padding-left: ${remCalc(63)};
          }
        `
      : ''}
  ${(props) =>
    (!props.theme.isParentSection && !props.theme.menuExpanded && !props.theme.sticky.desktop) ||
    (props.theme.routerType === 'article' && !props.theme.menuExpanded)
      ? css`
          && {
            padding-top: ${remCalc(19)};
            padding-left: ${remCalc(30)};
          }
        `
      : ''}
`

const MenuWrapper = styled.div`
  background: ${(props) => props.theme.bgColor};
  position: absolute;
  width: 100%;
  font-family: ${fontFamily.roboto};
  transform: translateY(${remCalc(15)});
  z-index: 100;
  visibility: hidden;
  height: calc(100vh - 136px);
  opacity: 0;
  transition:
    opacity 500ms ease,
    visibility 500ms ease;
  top: ${remCalc(136)};

  &.active {
    visibility: visible;
    opacity: 1;
  }
`
const MenuOverlay = styled.div`
  background: ${(props) => props.theme.menuBgColor};
  padding: ${remCalc(19, 0, 19, 0)};
`

const SectionPanel = styled.div`
  position: relative;
`

const MenuRight = styled.div`
  && {
    align-items: center;
    display: flex;
    justify-content: flex-end;
    padding-right: 0;
    .item {
      padding-left: ${remCalc(20)};
    }
  }
  svg {
    display: block;
  }
  ${(props) =>
    props.theme.sticky.desktop &&
    !props.theme.menuExpanded &&
    css`
      margin-top: ${remCalc(-10)};
    `}

  ${(props) =>
    !props.theme.sticky.desktop &&
    props.theme.isParentSection &&
    !props.theme.menuExpanded &&
    props.theme.routerType !== 'article' &&
    css`
      position: absolute;
      right: ${remCalc(32)};
      top: ${remCalc(25)};
    `}

  ${(props) =>
    props.theme.sticky.desktop &&
    props.theme.isParentSection &&
    !props.theme.menuExpanded &&
    css`
      margin-top: ${remCalc(-11)};
    `}

  ${(props) =>
    props.theme.menuExpanded &&
    css`
      margin-top: ${remCalc(-12)};
    `}

  ${(props) =>
    props.theme.routerType === 'article' &&
    !props.theme.sticky.desktop &&
    !props.theme.menuExpanded &&
    css`
      margin-top: ${remCalc(-15)};
    `}
`

const StyledCol = styled(Col)`
  ${(props) =>
    props.hasSubscribeBtn &&
    css`
      @media (min-width: 60em) {
        flex-basis: 56%;
        max-width: 56%;
      }
      @media (min-width: 70em) {
        flex-basis: 64%;
        max-width: 64%;
      }
    `}
  ${(props) =>
    !props.theme.sticky.desktop &&
    props.theme.isParentSection &&
    !props.theme.menuExpanded &&
    css`
      flex-basis: auto !important;
      max-width: 100% !important;
    `}
`

const ArticleTitle = styled.div`
  color: ${color.black};
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
  font-family: ${fontFamily.gothic};
  font-weight: bold;
  line-height: ${remCalc(32)};
  font-size: ${remCalc(18)};
`

const HeaderRow = styled(Row)`
  width: 100%;
  padding-right: 0;
  flex-wrap: nowrap;
  ${noGutter}
`

const ScmpLogoWrap = styled.div`
  display: block;
  margin: ${remCalc(32)} auto;
  text-align: center;
  font-size: ${remCalc(13)};
  line-height: ${remCalc(18)};

  a {
    display: block;
    font-weight: bold;
  }

  svg {
    height: ${remCalc(40)};
    width: ${remCalc(46)};
    margin-bottom: ${remCalc(8)};
  }
`

const StyledSubscribeButton = styled(SubscribeButton)`
  margin-right: ${remCalc(30)};
  ${mdTo1279px`display: none;`}
`

const StyledEpaperButton = styled(EpaperButton)`
  margin-right: ${remCalc(30)};
  ${mdTo1279px`display: none;`}
`

const StyledCommentDivider = styled.div`
  width: 1px;
  background-color: ${color['black-20']};
  height: 100%;
  margin-left: ${remCalc(10)};
  margin-right: ${remCalc(10)};
`
const StyledAvatar = styled(Avatar)`
  width: 48px;
  height: 48px;
`

const DesktopHeader = ({
  className,
  sticky,
  itemOnClick,
  theme,
  entityUuid,
  isParentSection,
  activeSection,
  toggleExpandedMenu,
}) => {
  const { headerState, menuState, rosettaState } = useStore()
  const menuStateValue = get(menuState, 'state', {})
  const subscriptionStatus = get(rosettaState, 'state.subscriptionStatus', null)
  const isShowRosetta = get(rosettaState, 'state.isShowRosetta', false)
  const [isShowSubscribeButton, setIsShowSubscribeButton] = useState(false)
  const [isShowEpaperButton, setIsShowEpaperButton] = useState(false)

  useEffect(() => {
    if (!isShowRosetta) return
    if (isSubscriber(subscriptionStatus)) {
      setIsShowEpaperButton(true)
      return
    }
    setIsShowSubscribeButton(true)
  }, [subscriptionStatus, isShowRosetta])

  // declaration
  const sections = get(menuStateValue, 'sections', [])
  const sectionDepth1 = filter(sections, { depth: 1 })
  const sectionDepth2 = filter(sections, { depth: 2 })
  const menuExpanded = !!get(headerState, 'state.menuExpanded')
  const headerTitle = get(headerState, 'state.headerEntity.title')
  const headerEntityId = get(headerState, 'state.headerEntity.entityId')
  const routerType = get(theme, 'routerType')

  const isStickyArticleHeader = headerTitle && sticky

  const MastheadButton = useCallback(() => {
    const headerState = menuExpanded ? 'expanded' : isStickyArticleHeader ? 'article' : 'default'
    if (isShowSubscribeButton) {
      return <StyledSubscribeButton headerState={headerState} />
    }
    if (isShowEpaperButton) {
      return <StyledEpaperButton checkAccess={true} />
    }
    return null
  }, [isShowSubscribeButton, isShowEpaperButton, isStickyArticleHeader, menuExpanded])

  return (
    <HeaderWrapper className={className}>
      <HeaderGrid>
        <HeaderRow>
          <LogoWrap md={2} lg={2} toSmall={!isParentSection}>
            <StyledLogo
              hideLabel={
                ((!isParentSection && !menuExpanded) ||
                  (sticky && !menuExpanded) ||
                  routerType === 'article' ||
                  theme.isStaticPage) &&
                !menuExpanded
              }
            />
          </LogoWrap>
          <NavItems md={14} lg={14} isParentSection={isParentSection} showTitle={headerTitle}>
            <Row between="xs">
              <StyledCol md={10} lg={10} hasSubscribeBtn={isShowSubscribeButton}>
                {isStickyArticleHeader && !menuExpanded ? (
                  <ArticleTitle>{headerTitle}</ArticleTitle>
                ) : (
                  <>
                    {((isParentSection && routerType !== 'article') ||
                      menuExpanded ||
                      entityUuid === 0) && (
                      <SectionPanel>
                        <StyledNavigationItemsDepth1
                          section={sectionDepth1}
                          activeSection={activeSection}
                          itemOnClick={itemOnClick}
                        />
                        {!menuExpanded && !sticky && routerType !== 'article' && (
                          <StyledNavigationItemsDepth2
                            section={sectionDepth2}
                            toggleable={false}
                            activeSection={activeSection}
                            itemOnClick={itemOnClick}
                          />
                        )}
                      </SectionPanel>
                    )}
                    {((!isParentSection && !menuExpanded) ||
                      (routerType === 'article' && !sticky && !menuExpanded)) && (
                      <Breadcrumb entityId={get(activeSection, 'depth1.entityId')} />
                    )}
                  </>
                )}
              </StyledCol>

              <MenuRight md={8} lg={8}>
                {sticky && !menuExpanded && headerEntityId && (
                  <>
                    <HeaderCommentButton storyId={headerEntityId} isDesktop={true} />
                    <StyledCommentDivider />
                  </>
                )}
                <MastheadButton />
                <StyledAvatar containerId="desktop-avatar-container" />
                <div className="item">
                  <a href="/yp/search" title="Search page">
                    <SearchIcon width={40} height={40} className="item-clickable" />
                  </a>
                </div>
                <div className="item">
                  {menuExpanded && (
                    <CloseIcon onClick={toggleExpandedMenu} width={18} className="item-clickable" />
                  )}
                  {!menuExpanded && (
                    <MenuIcon onClick={toggleExpandedMenu} width={18} className="item-clickable" />
                  )}
                </div>
              </MenuRight>
            </Row>
          </NavItems>
        </HeaderRow>
      </HeaderGrid>

      <MenuWrapper className={`${menuExpanded && 'active'}`}>
        <MenuOverlay>
          <Menu
            sections={sections}
            entityUuid={entityUuid}
            itemOnClick={itemOnClick}
            activeSection={activeSection}
          />
        </MenuOverlay>
        <ScmpLogoWrap>
          <ScmpHeader />
          Young Post is a unit of
          <Link href={SCMP_MAIN_SITE_DOMAIN}>South China Morning Post</Link>
        </ScmpLogoWrap>
      </MenuWrapper>
    </HeaderWrapper>
  )
}

DesktopHeader.propTypes = {
  sticky: PropTypes.bool,
  itemOnClick: PropTypes.func,
  isParentSection: PropTypes.bool,
  activeSection: PropTypes.object,
  toggleExpandedMenu: PropTypes.func,
}

export default withTheme(DesktopHeader)
