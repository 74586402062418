import { sections } from 'config/section.config'
import assign from 'lodash/assign'
import cloneDeep from 'lodash/assign'
import get from 'lodash/get'
import { getActiveSection } from 'utils/section'

const name = 'menuState'

const defaultState = {
  entityUuid: '',
  activeSection: {
    depth1: {},
    depth2: {},
    depth3: {},
  },
  sections,
}

const initialState = cloneDeep(defaultState)

// Please re-save storeHandler when you add new reducer
function reducer(state = initialState, { type, payload } = {}) {
  switch (type) {
    case 'UpdateSection': {
      const entityUuid = get(payload, 'entityUuid', '')
      const activeSection = getActiveSection(entityUuid)
      assign(state, {
        entityUuid,
        ...{ activeSection },
        bgColor: get(activeSection, 'depth1.bgColor'),
      })
      return {
        hideMe: false,
        ...state,
      }
    }
    case 'hideMe': {
      return {
        hideMe: true,
        entityUuid: '',
        ...state,
      }
    }
    default: {
      return state
    }
  }
}

export default {
  name,
  initialState,
  defaultState,
  reducer,
}
