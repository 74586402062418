import ScmpLogo from 'components/Common/Logo/ScmpLogo'
import FooterSocial from 'components/Footer/FooterSocial'
import Link from 'components/Link'
import { SCMP_MAIN_SITE_DOMAIN } from 'config/app.config'
import { format } from 'date-fns'
import get from 'lodash/get'
import map from 'lodash/map'
import PropTypes from 'prop-types'
import styled, { css } from 'styled-components'
import { remCalc, fontFamily, color, respondTo } from 'styles/mixins'

const StyledWrapper = styled.div`
  display: flex;
  flex-direction: column;
  ${respondTo.smAndDown`
    align-items: center;
    text-align: center;
  `}
`

const StyledRow = styled.div`
  display: flex;
  padding-top: ${remCalc(21)};
  ${respondTo.smAndDown`
    padding-top: ${remCalc(16)};
    flex-direction: column-reverse;
  `}
`

const StyledLeftCol = styled.div`
  ${respondTo.mdAndUp`
    flex: 3;
  `}
`

const StyledFooterSocial = styled(FooterSocial)`
  ${respondTo.mdAndUp`
    flex: 1;
  `}
`

const StyledListItem = styled.span`
  display: inline-block;
  ${(props) =>
    !props.isLast &&
    css`
      :after {
        content: '|';
        margin: ${remCalc(0, 16)};
      }
    `}
  ${respondTo.smAndDown`
    margin-bottom: ${remCalc(10)};
  `}
`

const StyledByline = styled.div`
  color: ${color.white};
  font-size: ${remCalc(16)};
  line-height: ${remCalc(32)};
  font-weight: bold;
  margin-bottom: ${remCalc(19)};
  ${respondTo.mdAndUp`
    margin-bottom: ${remCalc(8)};
  `}
`

const StyledScmpSpan = styled.span`
  display: ${(props) => (props.full ? 'inline-block' : 'none')};
  ${respondTo.smAndDown`
    display: ${(props) => (props.full ? 'none' : 'inline-block')};
  `}
`

const StyledLink = styled(Link)`
  text-transform: uppercase;
  text-decoration: none;
  font-family: ${fontFamily.roboto};
  color: ${color.white};
  font-size: ${remCalc(14)};
  line-height: ${remCalc(16)};
`

const StyledScmpLink = styled(Link)`
  border-radius: 4px;
  border: 1px solid #000;
  background: #fcff79;
  box-shadow: 0px 4px 0px 0px #000;
  display: inline-block;
  margin-inline-start: ${remCalc(16)};
  padding-block: ${remCalc(8)};
  padding-inline: ${remCalc(10)};
  color: #000;
  text-align: center;
  text-decoration: none;
  font-family: ${fontFamily.roboto};
  font-size: ${remCalc(12)};
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  text-transform: uppercase;
  ${respondTo.smAndDown`
    display: block;
    width: fit-content;
    margin: ${remCalc(12)} auto 0;
  `}
`

const StyleTextPanel = styled.div`
  ${respondTo.mdAndUp`
    padding-left: ${remCalc(25)};
  `}
`

const StyleCopyrightText = styled.div`
  text-decoration: none;
  font-family: ${fontFamily.roboto};
  color: ${color.white};
  font-weight: 500;
  font-size: ${remCalc(12)};
  line-height: ${remCalc(18)};
  ${respondTo.mdAndUp`
    line-height: ${remCalc(32)};
  `}
`

const StyledBrandingWrapper = styled.div`
  padding-top: ${remCalc(5)};
  display: flex;
  margin-bottom: ${remCalc(9)};
  ${respondTo.smAndDown`
    padding-top: 0;
    flex-direction: column;
    align-items: center;
  `}
`

const StyledDivider = styled.div`
  display: block;
  background-color: ${color.lightGray};
  width: 100%;
  height: ${remCalc(1)};
  ${respondTo.smAndDown`
    display: none;
  `}
`

const StyledScmpLogo = styled(ScmpLogo)`
  ${respondTo.smAndDown`
    margin-bottom: ${remCalc(11)};
  `}
`

const StyledFooterLinkWrapper = styled.div`
  ${respondTo.smAndDown`
    max-width: ${remCalc(300)};
  `}
`

const FooterBottom = (props) => {
  const year = format(Date.now(), 'yyyy')
  const itemOnClick = get(props, 'itemOnClick')
  const items = map(get(props, 'items', []), (item, index) => {
    const itemOnClickHandler = (next) => {
      if (itemOnClick) itemOnClick(item.text, next)
    }
    return (
      <StyledListItem key={index} isLast={index === get(props, 'items.length') - 1}>
        <StyledLink href={item.href} routerMiddleware={itemOnClickHandler}>
          {item.text}
        </StyledLink>
      </StyledListItem>
    )
  })
  return (
    <StyledWrapper>
      <StyledDivider />
      <StyledRow>
        <StyledLeftCol>
          <StyledBrandingWrapper>
            <StyledScmpLogo />
            <StyleTextPanel>
              <StyledByline>
                YP is a unit of&nbsp;
                <StyledScmpSpan full={true}>South China Morning Post.</StyledScmpSpan>
                <StyledScmpSpan>SCMP</StyledScmpSpan>
                <StyledScmpLink href={SCMP_MAIN_SITE_DOMAIN}>Visit scmp.com</StyledScmpLink>
              </StyledByline>
              <StyledFooterLinkWrapper>{items}</StyledFooterLinkWrapper>
            </StyleTextPanel>
          </StyledBrandingWrapper>
          <StyleCopyrightText>
            &copy; {year} South China Morning Post Publisher Ltd. All rights reserved.
          </StyleCopyrightText>
        </StyledLeftCol>
        <StyledFooterSocial />
      </StyledRow>
    </StyledWrapper>
  )
}

FooterBottom.propTypes = {
  items: PropTypes.array.isRequired,
  itemOnClick: PropTypes.func,
}

export default FooterBottom
