import cloneDeep from 'lodash/assign'
import assign from 'lodash/assign'
import forEach from 'lodash/forEach'

const name = 'node'

const defaultState = {
  originalNode: {},
  node: {},
}

const initialState = cloneDeep(defaultState)

// Please update the reducer counter in storeHandler when you add new reducer
function reducer(state = initialState, { type, nodes } = {}) {
  switch (type) {
    case 'ADD':
      forEach(nodes, ({ originalNode, processedNode }) => {
        assign(state.node, { originalNode, node: processedNode })
      })
      return {
        ...state,
      }
    default:
      return state
  }
}

export default {
  name,
  initialState,
  defaultState,
  reducer,
}
