import Avatar from 'components/Avatar'
import Logo from 'components/Common/Logo'
import Breadcrumb from 'components/Header/children/Breadcrumb'
import NavigationItemsDepth1 from 'components/Header/children/NavigationItemsDepth1'
import NestedMenu from 'components/Header/children/NestedMenu'
import EpaperButton from 'components/Header/EpaperButton'
import HeaderCommentButton from 'components/Header/HeaderCommentButton'
import SubscribeButton from 'components/Header/SubscribeButton'
import filter from 'lodash/filter'
import get from 'lodash/get'
import PropTypes from 'prop-types'
import CloseIcon from 'public/assets/svg/close.svg'
import MenuIcon from 'public/assets/svg/menu.svg'
import SearchIcon from 'public/assets/svg/search.svg'
import { useCallback, useEffect, useState } from 'react'
import { useStore } from 'store'
import styled, { css, withTheme } from 'styled-components'
import { remCalc, color } from 'styles/mixins'
import { isSubscriber } from 'utils/rosetta'

const HeaderWrapper = styled.div`
  padding-top: ${remCalc(24)};
  background-color: ${(props) => props.theme.bgColor};
  padding-bottom: ${remCalc(0)};

  ${(props) =>
    !props.theme.sticky.mobile &&
    props.theme.isParentSection &&
    !props.theme.menuExpanded &&
    css`
      padding-top: ${remCalc(16)};
    `}

  ${(props) =>
    props.theme.routerType === 'article'
      ? css`
          padding-top: ${remCalc(16)};
          padding-bottom: ${remCalc(16)};
          background-color: transparent;
        `
      : ''}
  ${(props) =>
    props.theme.sticky.mobile
      ? css`
          padding-top: ${remCalc(16)};
          padding-bottom: ${remCalc(16)};
          background-color: ${(props) => props.theme.bgColorTrans};
        `
      : ''}
  ${(props) =>
    (!props.theme.sticky.mobile &&
      props.theme.isParentSection &&
      !props.theme.routerType === 'article') ||
    props.theme.menuExpanded
      ? css`
          padding-bottom: 0;
          padding-top: ${remCalc(24)};
          background-color: ${(props) => props.theme.bgColor};
        `
      : ''}
`

const Nav = styled.nav`
  margin: ${remCalc(0, 24)};
  position: relative;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  ${(props) =>
    props.theme.isParentSection && !props.theme.isStaticPage
      ? css`
          justify-content: ${(props) =>
            props.theme.sticky.mobile ||
            props.theme.routerType === 'article' ||
            props.theme.menuExpanded
              ? 'flex-start'
              : 'center'};
        `
      : ''}
`

const LogoWrap = styled.div`
  width: ${remCalc(104)};
  will-change: transform;
  ${(props) =>
    props.theme.sticky.mobile ||
    props.theme.routerType === 'article' ||
    !props.theme.isParentSection ||
    props.theme.isStaticPage
      ? css`
          width: ${remCalc(57)};
          padding-right: ${remCalc(10)};
        `
      : ''}

  ${(props) =>
    props.theme.menuExpanded
      ? css`
          width: ${remCalc(96)};
          padding-right: ${remCalc(0)};
        `
      : ''}
`

const MenuItem = styled.div`
  display: flex;
  align-items: center;
  position: absolute;
  padding-top: ${remCalc(1)};
  top: 0;
`

const MenuRight = styled(MenuItem)`
  right: 0;
  ${(props) =>
    (props.theme.sticky.mobile ||
      !props.theme.isParentSection ||
      props.theme.routerType === 'article') &&
    !props.theme.menuExpanded
      ? css`
          top: 50%;
          transform: translateY(-50%);
        `
      : ''}
`

const MenuLeft = styled(MenuItem)`
  left: 0;
`

const StyledNavigationItemsDepth1 = styled(NavigationItemsDepth1)`
  overflow-x: auto;
  margin: 0;
  padding: ${remCalc(24, 20, 0)};
  line-height: ${remCalc(20)};
  font-size: ${remCalc(22)};
  letter-spacing: ${remCalc(1.6)};
  line-height: ${remCalc(20)};
  justify-content: safe center;
  ${(props) =>
    props.theme.isParentSection &&
    !props.theme.sticky.mobile &&
    !props.theme.menuExpanded &&
    css`
      padding-top: ${remCalc(20)};
    `}
  ${(props) =>
    props.theme.isStaticPage &&
    css`
      display: none;
    `}

  .item {
    padding: ${remCalc(0, 20, 6, 0)};
    position: relative;

    &:last-child {
      padding-right: 0;
    }
    ${(props) =>
      props.withIndicator
        ? css`
            padding-bottom: ${remCalc(14)};
          `
        : ''}
    .active {
      ${(props) =>
        props.withIndicator
          ? css`
              &:after {
                content: '';
                position: absolute;
                width: 0;
                height: 0;
                border-style: solid;
                bottom: ${remCalc(-15)};
                left: 50%;
                transform: translateX(-50%);
                border-width: ${remCalc(0, 8, 12, 8)};
                border-color: transparent transparent ${(props) => props.theme.menuBgColor}
                  transparent;
              }
            `
          : `
        &:after {
          border-bottom: ${remCalc(2)} solid ${color.black};
          padding-top: ${remCalc(1)};
          content: '';
          display: block;
        }
      `}
    }
  }
`

const NestedMenuWrapper = styled.div`
  opacity: 0;
  visibility: hidden;
  transition:
    opacity 300ms ease,
    visibility 500ms ease;
  height: calc(100vh - 180px);
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  background-color: ${(props) => props.theme.bgColor};

  > div {
    transform: scale(0.8);
    opacity: 0;
    transform-origin: top;
    transition:
      transform 200ms ease 50ms,
      opacity 500ms ease;
  }

  &.active {
    opacity: 1;
    display: block;
    visibility: visible;
    transform: scale(1);
    transition:
      opacity 300ms ease,
      visibility 300ms ease;

    > div {
      opacity: 1;
      transform: scale(1);
    }
  }
`

const StyledCommentDivider = styled.div`
  width: 1px;
  background-color: ${color['black-20']};
  height: ${remCalc(32)};
  margin-left: ${remCalc(10)};
  margin-right: ${remCalc(10)};
`

const StyledAvatar = styled(Avatar)`
  width: ${remCalc(32)};
  height: ${remCalc(32)};
  margin-right: ${remCalc(8)};
  ${(props) =>
    props.theme.menuExpanded &&
    css`
      opacity: 0;
    `}
`

const StyledSearchButton = styled.a`
  display: flex;
  margin-right: ${remCalc(11.5)};
`

const StyledEpaperButton = styled(EpaperButton)`
  margin-right: ${remCalc(10)};
`

const StyledMiniEpaperButton = styled(EpaperButton)`
  ${(props) =>
    props.theme.menuExpanded &&
    css`
      opacity: 0;
    `}
`

const MobileHeader = ({
  className,
  sticky,
  itemOnClick,
  theme,
  entityId,
  isParentSection,
  activeSection,
  toggleExpandedMenu,
}) => {
  const { headerState, menuState, rosettaState } = useStore()
  // declaration
  const headerStateValue = get(headerState, 'state')
  const menuStateValue = get(menuState, 'state')
  const headerEntityId = get(headerStateValue, 'headerEntity.entityId')
  const menuExpanded = !!get(headerStateValue, 'menuExpanded')
  const sections = get(menuStateValue, 'sections', [])
  const sectionDepth1 = filter(sections, { depth: 1 })
  const routerType = get(theme, 'routerType')
  const isArticleSticky = routerType === 'article' && sticky
  const subscriptionStatus = get(rosettaState, 'state.subscriptionStatus', null)
  const isShowRosetta = get(rosettaState, 'state.isShowRosetta', null)
  const [isShowSubscribeButton, setIsShowSubscribeButton] = useState(false)
  const [isShowEpaperButton, setIsShowEpaperButton] = useState(false)

  useEffect(() => {
    if (!isShowRosetta) return
    if (isSubscriber(subscriptionStatus)) {
      setIsShowEpaperButton(true)
      return
    }
    setIsShowSubscribeButton(true)
  }, [subscriptionStatus, isShowRosetta])

  const MastheadButton = useCallback(() => {
    if (isShowSubscribeButton) {
      return <SubscribeButton headerState="expanded" />
    }
    if (isShowEpaperButton) {
      return <StyledEpaperButton checkAccess={true} />
    }
    return null
  }, [isShowSubscribeButton, isShowEpaperButton])

  return (
    <HeaderWrapper className={className} menuExpanded={menuExpanded}>
      <Nav>
        <MenuLeft>
          {routerType === 'static' && !sticky && (
            <>
              <StyledAvatar containerId="mobile-avatar-container" />
              {isSubscriber(subscriptionStatus) && (
                <StyledMiniEpaperButton variant="mini" checkAccess="true" />
              )}
            </>
          )}
        </MenuLeft>

        <LogoWrap>
          <Logo
            hideLabel={
              (sticky || !isParentSection || routerType === 'article' || theme.isStaticPage) &&
              !menuExpanded
            }
            id={`mobile-logo-${entityId}`}
          />
        </LogoWrap>

        {sticky && routerType !== 'article' && !isParentSection && !menuExpanded && (
          <Breadcrumb hideDepth2={true} />
        )}
        <MenuRight>
          {menuExpanded ? (
            <>
              <MastheadButton />
              <StyledSearchButton href="/yp/search" title="Search page">
                <SearchIcon width={40} height={40} />
              </StyledSearchButton>
              <CloseIcon onClick={toggleExpandedMenu} width={18} />
            </>
          ) : (
            <>
              {headerEntityId && isArticleSticky && (
                <>
                  <HeaderCommentButton storyId={headerEntityId} />
                  <StyledCommentDivider />
                </>
              )}
              {routerType !== 'static' && <StyledAvatar containerId="mobile-avatar-container" />}
              <StyledSearchButton href="/yp/search" title="Search page">
                <SearchIcon width={40} height={40} />
              </StyledSearchButton>
              <MenuIcon onClick={toggleExpandedMenu} width={18} />
            </>
          )}
        </MenuRight>
      </Nav>

      {((!sticky && isParentSection && routerType !== 'article') || menuExpanded) && (
        <StyledNavigationItemsDepth1
          withIndicator={menuExpanded}
          section={sectionDepth1}
          activeSection={activeSection}
          itemOnClick={itemOnClick}
          isMobile={true}
        />
      )}

      <NestedMenuWrapper className={`${menuExpanded && 'active'}`}>
        <NestedMenu
          entityId={entityId}
          sections={sections}
          activeSection={activeSection}
          itemOnClick={itemOnClick}
        />
      </NestedMenuWrapper>
    </HeaderWrapper>
  )
}

MobileHeader.propTypes = {
  sticky: PropTypes.bool,
  switchSection: PropTypes.func,
  isParentSection: PropTypes.bool,
  activeSection: PropTypes.object,
  toggleExpandedMenu: PropTypes.func,
}

export default withTheme(MobileHeader)
