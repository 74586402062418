import { setVariables, variablesMapping } from 'lib/gtm'
import get from 'lodash/get'
import isEmpty from 'lodash/isEmpty'
import { useEffect, useState } from 'react'
import { useStore } from 'store'

import { GTM_ID } from '../../config/app.config'

const addGtmScript = () => {
  if (GTM_ID && typeof window !== 'undefined' && window.isYpGtmScriptLoaded !== true) {
    let gtmscript = document.createElement('script')
    gtmscript.innerHTML = `<!-- Google Tag Manager -->
    (function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start': new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0], j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src= 'https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f); })(window,document,'script','dataLayer','${GTM_ID}');
    <!-- End Google Tag Manager -->`
    let headDom = document.getElementsByTagName('head')[0]
    headDom.appendChild(gtmscript)
    window.isYpGtmScriptLoaded = true
  }
}

const Tracking = ({ query }) => {
  const { pathData, type } = query
  const { rosettaState } = useStore()
  const subscriptionStatus = get(rosettaState, 'state.subscriptionStatus', null)

  let savedDimensions = {}
  const [firstPVComplete, setFirstPVComplete] = useState(false)

  const setVariablesEventHandler = ({ detail }) => {
    const userRole = get(detail, 'page.userRole', null)
    if (!isEmpty(userRole)) {
      savedDimensions = detail
    }

    if (!isEmpty(detail)) {
      window.dataLayer = window.dataLayer || []
      window.dataLayer.push({
        _clear: true,
        ...detail,
      })
    }
  }

  const resetVariablesEventHandler = () => {
    window.dataLayer = window.dataLayer || []
    window.dataLayer.push({ page: undefined })
    if (!isEmpty(savedDimensions)) {
      window.dataLayer.push(savedDimensions)
    }
  }

  useEffect(() => {
    window.document.addEventListener('set_variables', setVariablesEventHandler)
    window.document.addEventListener('reset_variables', resetVariablesEventHandler)

    return () => {
      window.document.removeEventListener('set_variables', setVariablesEventHandler)
      window.document.removeEventListener('reset_variables', resetVariablesEventHandler)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => {
    // wait until subscriptionStatus is not null
    if (subscriptionStatus !== null) {
      // Set up initial variables.
      if (pathData && !firstPVComplete) {
        setVariables(variablesMapping(type, pathData))
        setFirstPVComplete(true)
      }

      addGtmScript()

      // TODO: Move back all the pushVirtualView here while query change
      // CD data shd be ready when contructing the client side <Link> component
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pathData, subscriptionStatus])

  return (
    <iframe
      src={`https://www.googletagmanager.com/ns.html?id=${GTM_ID}`}
      height={0}
      width={0}
      style={{ display: 'none', visibility: 'hidden' }}
    ></iframe>
  )
}

export default Tracking
