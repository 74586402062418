import Link from 'components/Link'
import get from 'lodash/get'
import map from 'lodash/map'
import PropTypes from 'prop-types'
import styled, { css } from 'styled-components'
import { remCalc, fontFamily, color } from 'styles/mixins'

const Item = styled.span`
  text-transform: uppercase;
  display: inline-block;
  font-family: ${fontFamily.roboto};
`
const StyledLink = styled(Link)`
  text-decoration: none;
  text-transform: uppercase;
  color: ${color.white};
  font-size: ${remCalc(14)};
  line-height: ${remCalc(23)};
  ${(props) =>
    !props.isLast &&
    css`
      :after {
        content: '//';
        margin: ${remCalc(0, 5)};
      }
    `}
`

const FooterTopics = (props) => {
  const itemOnClick = get(props, 'itemOnClick')
  const items = map(get(props, 'items', []), (item, index) => {
    const itemOnClickHandler = (next) => {
      if (itemOnClick) itemOnClick(item.text, next)
    }

    return (
      <Item key={index}>
        <StyledLink
          isLast={index === get(props, 'items.length') - 1}
          href={item.href}
          routerMiddleware={itemOnClickHandler}
        >
          {item.text}
        </StyledLink>
      </Item>
    )
  })

  return <div>{items}</div>
}

FooterTopics.propTypes = {
  items: PropTypes.array.isRequired,
  itemOnClick: PropTypes.func,
}

export default FooterTopics
