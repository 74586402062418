import cloneDeep from 'lodash/cloneDeep'

const defaultState = {
  initGtm: false,
  delayInitGtm: false,
  dataLayerVariables: {
    // GTM Custom Dimension Variables Schema:
    page: {
      // 1 User Role
      // 2
      publishedDate: null,
      // 3
      authors: null,
      // 4
      sections: null,
      // 5
      topics: null,
      // 8
      webHeadline: null,
      // 9
      socialHeadline: null,
      // 10
      printHeadline: null,
      // 11
      articleID: null,
      // 12
      searchKeyword: null,
      // 13
      sponsor: null,
      // 15
      scmpWriter: null,
      // 16
      scmpCopyright: null,
      // 17
      primarySection: null,
      // 23
      topicID: null,
      // 24
      sourceTracking: null,
      // 27
      contentType: null,
      // 28
      articleType: null,
      // 34 Drupal UID
      // 36 Canonical URL
      canonicalURL: null,
      // 42 scmp
      // 48 oneplusXUid
    },
  },
}

const initialState = cloneDeep(defaultState)

// Please re-save storeHandler when you add new reducer
function reducer(state = initialState, { action, payload } = {}) {
  switch (action) {
    case 'initGtm': {
      return {
        ...state,
        ...{
          initGtm: true,
        },
      }
    }
    case 'setVariables': {
      return {
        ...state,
        ...{
          dataLayerVariables: payload,
        },
      }
    }
    default: {
      return state
    }
  }
}

export default {
  name: 'trackingState',
  initialState,
  defaultState,
  reducer,
}
