import cloneDeep from 'lodash/assign'
import assign from 'lodash/assign'

const name = 'footerState'

const defaultState = {
  show: true,
}

const initialState = cloneDeep(defaultState)

// Please re-save storeHandler when you add new reducer
function reducer(state = initialState, { type } = {}) {
  switch (type) {
    case 'ShowFooter':
      assign(state, { show: true })
      return {
        ...state,
      }
    case 'HideFooter':
      assign(state, { show: false })
      return {
        ...state,
      }
    default:
      return state
  }
}

export default {
  name,
  initialState,
  defaultState,
  reducer,
}
