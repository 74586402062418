import cloneDeep from 'lodash/cloneDeep'
import get from 'lodash/get'

const name = 'userState'

const defaultState = {
  userInfo: null,
}

const initialState = cloneDeep(defaultState)

// Please re-save storeHandler when you add new reducer
function reducer(state = initialState, { action, payload } = {}) {
  switch (action) {
    case 'UpdateUserInfo': {
      state.userInfo = get(payload, 'userInfo', {})
      return {
        ...state,
      }
    }
    default: {
      return state
    }
  }
}

export default {
  name,
  initialState,
  defaultState,
  reducer,
}
