import { Grid, Row, Col } from '@product/react-styled-flexboxgrid'
import map from 'lodash/map'
import omit from 'lodash/omit'
import range from 'lodash/range'
import { useState, useEffect } from 'react'
import styled from 'styled-components'
import { button } from 'styles/components'
import { remCalc, color } from 'styles/mixins'

import { GENERAL } from '../../config/app.config'

const Container = styled.div`
  position: fixed;
  z-index: 100;
  width: 100vw;
`

const TestItem = styled.div`
  /* We center the contents of these items. You can also do this with flexbox too! */
  display: grid;
  justify-content: center;
  align-items: center;
  border: ${remCalc(1)} solid ${color.yellow};
  border-radius: ${remCalc(3)};
  font-size: ${remCalc(30)};
  background-color: ${color.steel}; /* best colour */
  opacity: 0.5;
  height: 100vh;
`

const Toggle = styled.div`
  ${button}
  margin-top: ${remCalc(18.5)};
  height: ${remCalc(30)};
  width: ${remCalc(30)};
  background-color: ${color.cement};
  border: 1px solid ${color.red};
  border-radius: ${remCalc(2)};
  position: fixed;
  top: ${remCalc(10)};
  left: ${remCalc(10)};
  z-index: 101;
`

const GridHelper = ({ isShow = true }) => {
  const [showGrid, setShowGrid] = useState(isShow)
  const gridRuler = map(omit(range(GENERAL.gridSize + 1), 0), (index) => {
    return (
      <Col xs={1} sm={1} md={1} lg={1} key={'grid-item-container' + index}>
        <TestItem key={'grid-item-' + index}>{index}</TestItem>
      </Col>
    )
  })

  const toggleGrid = () => {
    setShowGrid(!showGrid)
  }

  useEffect(() => {
    setShowGrid(isShow)
  }, [isShow])

  return (
    <Container>
      {showGrid ? (
        <Grid>
          <Row>{gridRuler}</Row>
        </Grid>
      ) : (
        <></>
      )}
      <Toggle onClick={toggleGrid}></Toggle>
    </Container>
  )
}

export default GridHelper
