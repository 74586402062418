import SocialButton from 'components/Social/SocialButton'
import { setVariables } from 'lib/gtm'
import styled, { css } from 'styled-components'
import { remCalc, fontFamily, color, respondTo } from 'styles/mixins'

const StyledWrapper = styled.div`
  display: flex;
  flex-direction: column;
  ${respondTo.mdAndUp`
    padding-top: ${remCalc(2)};
  `}
`

const StyledHeading = styled.div`
  font-family: ${fontFamily.gothic};
  font-weight: bold;
  font-size: ${remCalc(20)};
  line-height: ${remCalc(28)};
  text-transform: uppercase;
  text-align: center;
  margin-bottom: ${remCalc(16)};
  ${respondTo.mdAndUp`
    text-align: right;
    margin-bottom: ${remCalc(8)};
  `}
`

const StyledButtons = styled.div`
  display: flex;
  justify-content: center;
  margin-bottom: ${remCalc(40)};
`

const StyledSocialButton = styled(SocialButton)`
  fill: ${color.black};
  width: ${remCalc(42)};
  height: ${remCalc(42)};
  background-color: ${color.white};
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: ${remCalc(0, 8)};

  ${respondTo.mdAndUp`
    margin: ${remCalc(0, 0, 0, 15)};
    width: ${remCalc(32)};
    height: ${remCalc(32)};
  `}

  svg {
    ${respondTo.mdAndUp`
      ${(props) =>
        props.type === 'facebook' &&
        css`
          width: ${remCalc(10)};
          height: ${remCalc(20)};
        `}
      ${(props) =>
        props.type === 'twitter' &&
        css`
          width: ${remCalc(20)};
          height: ${remCalc(18)};
        `}
      ${(props) =>
        props.type === 'instagram' &&
        css`
          width: ${remCalc(20)};
          height: ${remCalc(20)};
        `}
      ${(props) =>
        props.type === 'youtube' &&
        css`
          width: ${remCalc(22)};
          height: ${remCalc(14)};
        `}
    `}
  }
`

const FooterSocial = () => {
  const handleClick = (type) => {
    setVariables({
      event: 'tracking',
      tracking: {
        category: 'Footer',
        action: 'Footer/Click',
        label: `Footer/Click/Stay Connected/${type}`,
      },
    })
  }
  return (
    <StyledWrapper>
      <StyledHeading>find us at</StyledHeading>
      <StyledButtons>
        <StyledSocialButton
          width="11"
          height="23"
          type={'facebook'}
          onClickCallback={handleClick}
        />
        <StyledSocialButton width="22" height="18" type={'twitter'} onClickCallback={handleClick} />
        <StyledSocialButton
          width="25"
          height="24"
          type={'instagram'}
          onClickCallback={handleClick}
        />
        <StyledSocialButton width="26" height="16" type={'youtube'} onClickCallback={handleClick} />
      </StyledButtons>
    </StyledWrapper>
  )
}

FooterSocial.propTypes = {}

export default FooterSocial
