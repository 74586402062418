const $baseFontSize = 16

const parseValueUnit = (valueUnit = '') => {
  const match = ('' + valueUnit).match(/-?(\d*\.?\d*)(.*)/)
  return {
    value: Array.isArray(match) && match.length > 1 ? parseFloat(match[0], 10) : null,
    unit: Array.isArray(match) && match.length > 2 ? match[2] : '',
  }
}

const remCalc = (...args) => {
  return args
    .map((arg) => {
      const { value, unit } = parseValueUnit(arg)
      return unit === '' || unit === 'px' ? `${(value / $baseFontSize).toFixed(3)}rem` : arg
    })
    .join(' ')
}

export default remCalc
