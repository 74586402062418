import https from 'https'

import { ApolloClient, ApolloLink, HttpLink, InMemoryCache } from '@apollo/client'
import fetch from 'isomorphic-unfetch'

import { CONTENT_API_URI, CONTENT_API_KEY, D8_API_URI, C3PO_API_URI } from '../../config/app.config'
import { INTROSPECTION_FRAGMENT_POSSIBLE_TYPES } from '../fragments/cs'

let apolloClient = null

// Polyfill fetch() on the server (used by apollo-client)
if (!process.browser) {
  global.fetch = fetch
}

function create(initialState, noCache) {
  const httpLinks = {
    contentService: new HttpLink({
      uri: CONTENT_API_URI,
      headers: {
        apikey: CONTENT_API_KEY,
      },
      fetchOptions: {
        agent: new https.Agent({ rejectUnauthorized: false }),
      },
    }),
    d8: new HttpLink({
      uri: D8_API_URI,
      credentials: 'include',
      headers: {
        apikey: CONTENT_API_KEY,
      },
      fetchOptions: {
        agent: new https.Agent({ rejectUnauthorized: false }),
      },
    }),
    c3poMiddleware: new HttpLink({
      uri: C3PO_API_URI,
      credentials: 'include',
      headers: {
        apikey: CONTENT_API_KEY,
      },
      fetchOptions: {
        agent: new https.Agent({ rejectUnauthorized: false }),
      },
    }),
  }
  return new ApolloClient({
    connectToDevTools: process.browser,
    ssrMode: !process.browser, // Disables forceFetch on the server (so queries are only run once)
    credentials: 'include',
    link: ApolloLink.from([
      ApolloLink.split((operation) => operation.getContext().clientName === 'd8', httpLinks.d8),
      ApolloLink.split(
        (operation) => operation.getContext().clientName === 'c3po-middleware',
        httpLinks.c3poMiddleware,
      ),
      // default call
      httpLinks.contentService,
    ]),
    cache: new InMemoryCache({
      possibleTypes: {
        QueueItem: INTROSPECTION_FRAGMENT_POSSIBLE_TYPES,
        UrlPath: INTROSPECTION_FRAGMENT_POSSIBLE_TYPES,
      },
      typePolicies: {
        Article: {
          merge: true,
        },
        Queue: {
          merge: true,
        },
      },
    }).restore(initialState || {}),
    ...(noCache
      ? {
          defaultOptions: {
            query: {
              fetchPolicy: 'no-cache',
            },
          },
        }
      : {}),
  })
}

export default function initApollo(initialState, noCache = false) {
  // Make sure to create a new client for every server-side request so that data
  // isn't shared between connections (which would be bad)
  if (!process.browser) {
    return create(initialState, noCache)
  }

  // Reuse client on the client-side
  if (!apolloClient) {
    apolloClient = create(initialState, noCache)
  }

  return apolloClient
}
