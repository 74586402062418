import { GENERAL } from '../../config/app.config'

export default {
  flexboxgrid: {
    gridSize: GENERAL.gridSize, // columns
    gutterWidth: GENERAL.gutterWidth / GENERAL.baseFontSize, // rem
    outerMargin: GENERAL.outerMargin / GENERAL.baseFontSize, // rem
    mediaQuery: 'only screen',
    fluid: true,
    container: {
      sm: (GENERAL.container.sm - GENERAL.outerMargin * 2) / GENERAL.baseFontSize, // rem
      md: GENERAL.container.md / GENERAL.baseFontSize, // rem
      lg: GENERAL.container.lg / GENERAL.baseFontSize, // rem
    },
    breakpoints: {
      xs: 0, // em
      sm: GENERAL.breakpoints.sm / GENERAL.baseFontSize, // em
      md: GENERAL.breakpoints.md / GENERAL.baseFontSize, // em
      lg: GENERAL.breakpoints.lg / GENERAL.baseFontSize, // em
    },
  },
  variables: {
    baseFontSize: GENERAL.baseFontSize,
  },
}
