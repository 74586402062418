const color = {
  black: '#000000',
  black07: 'rgba(0,0,0,0.07)',
  'black-20': 'rgba(0,0,0,0.2)',
  black36: 'rgba(0,0,0,0.36)',
  'black-50': 'rgba(0,0,0,0.5)',
  'black-60': 'rgba(0,0,0,0.6)',
  black70: 'rgba(0,0,0,0.7)',
  'black-90': 'rgba(0,0,0,0.9)',
  'black-80': 'rgba(0,0,0,0.8)',
  'black-10': 'rgba(0,0,0,0.1)',
  'black-40': 'rgba(0,0,0,0.4)',
  'black-15': 'rgba(0,0,0,0.15)',
  white: '#FFFFFF',
  'white-60': 'rgba(255,255,255,0.6)',
  muddywhite: '#EBECED',
  softwhite: '#F5F5F5',
  grayday: 'rgba(0,0,0,0.5)',
  tarmack: '#303030',
  solidgray: '#666666',
  anothergray: '#C3C3C3',
  steel: '#6F6F6F',
  darkgray: '#989898',
  'gray-0': 'rgba(252,255,121,0)',
  gray30: 'rgba(102,102,102,0.3)',
  cement: '#DDDDDD',
  gatorade: '#86F7FF',
  mint: '#51FFB8',
  whatsapp: '#24D366',
  seafoam: '#9FFFD8',
  'seafoam-70': 'rgba(159,255,216,0.7)',
  seafoam90: 'rgba(159,255,216,0.9)',
  seafoam0: 'rgba(159,255,216,0)',
  seaweed: '#7EEACD',
  shamrock: '#3ED6A4',
  greengrape: '#ABFF9F',
  teal: '#00A09C',
  teal0: 'rgba(0, 160, 156, 0)',
  frogger: '#219F81',
  softteal: '#34B8B5',
  softteal50: 'rgba(52,184,181,0.5)',
  wetsunset: '#56B0AF',
  cadetblue: '#5f9ea0',
  lilac: '#BFCFFF',
  lavender: '#A8BEFF',
  'lavender-70': 'rgba(168,190,255,0.8)',
  lavender90: 'rgba(168,190,255,0.9)',
  lavender0: 'rgba(168,190,255,0)',
  lightlavender: '#BAA8FF',
  softblue: '#8DA9FC',
  telegram: '#0088cc',
  cornflowerBlue: '#6989FC',
  dirtylavender: '#5672AE',
  bluehouse: '#4563BC',
  bluehouse50: 'rgba(69,99,188,0.5)',
  bluehouse0: 'rgba(69,99,188,0)',
  facebook: '#3C5997',
  popo: '#0E2D86',
  twitter: '#1DA1F1',
  purple: '#9544BD',
  pinksunset: '#C85E90',
  candyfloss: '#EFD0FF',
  peach: '#FFB2B2',
  tangerine: '#FF8A8A',
  yellowStraw: '#E2E563',
  yellowPear: '#C8CD27',
  'yellowPear-70': 'rgba(252,255,121,0.7)',
  yellowPear0: 'rgba(252,255,121,0)',
  cloudysunset: '#C9C490',
  toxicsunset: '#D2D881',
  sunshineyellow: '#FCFF79',
  sunshineyellow90: 'rgba(252,255,121, 0.9)',
  sunshineyellow0: 'rgba(252,255,121, 0)',
  sunshiesyellowmuddy: '#E2E564',
  avocado: '#97B100',
  avocado0: 'rgba(151, 177, 0, 0)',
  avocadodayone: '#96990C',
  avocadodaytwo: '#7F8200',
  yellow: '#FFCA05',
  brown: '#222',
  red: '#ff0000',
  darkGray: '#303030',
  gray: '#989898',
  navy: '#0E2D86',
  spanishGray: '#868686',
  lightGray: '#979797',
  sulu: '#BFEA7A',
  laserLemon: '#FBFF60',
  curiousBlue: '#1CA5E5',
  dodgerBlue: '#527DFD',
  melrose: '#D4BFFF',
  riptide: '#7feacd',
  persimmon: '#FF5B5B',
  lightPurple: '#E2CDFF',
  lightPurple0: 'rgba(226, 205, 255, 0)',
  'lightPurple-70': 'rgba(226, 205, 255, 0.7)',
  lightPurple90: 'rgba(226, 205, 255, 0.9)',
  mediumPurple: '#C3ACE9',
  mediumPurple0: 'rgba(195, 172, 233, 0)',
  darkPurple: '#8669B7',
  wisteriaPurple: '#8667B7',
  wisteriaPurple0: 'rgba(134,103,183,0)',
  wisteriaPurple50: 'rgba(134,103,183,0.5)',
  heliotrope: '#ED91FF',
  fuchsiaBlue: '#8C65BC',
  heliotropeapprox: '#CC78FF',
  pinkLace: '#FFBFF5',
  bilobaFlower: '#C3ABE9',
}
export default color

// color name generator : http://chir.ag/projects/name-that-color/
