import RosettaSDK from '@product/rosetta-sdk'
import { EPAPER_LINK } from 'config/app.config'
import PropTypes from 'prop-types'
import EpaperIcon from 'public/assets/svg/icon-epaper.svg'
import styled from 'styled-components'
import { color, fontFamily, remCalc } from 'styles/mixins'

const DefaultEpaperButton = styled.a`
  width: fit-content;
  white-space: nowrap;
  color: ${color.black};
  text-align: center;
  font-family: ${fontFamily.roboto};
  font-size: ${remCalc(16)};
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  text-decoration: none;
  text-transform: uppercase;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: ${remCalc(4)};
  padding-block: ${remCalc(8)};
  padding-inline: ${remCalc(16)};
  border-radius: 4px;
  border: 2px solid ${color.black};
  background: ${color.white};
  box-shadow: 0px 4px 0px 0px ${color.black};
`

const MiniEpaperButton = styled.a`
  display: flex;
  width: ${remCalc(32)};
  height: ${remCalc(32)};
  flex-direction: column;
  justify-content: center;
  align-items: center;
  flex-shrink: 0;
  border-radius: ${remCalc(100)};
  border: 1.5px solid #000;
  background: ${color.white};
  filter: invert(1);
`

const EpaperButton = ({ className, variant = 'default', checkAccess = false }) => {
  const isYPSubscriber = RosettaSDK?.rulesEngine?.getOutcome('user.isYPSubscriber')
  if (checkAccess && !isYPSubscriber) return null

  const buttonProps = {
    className,
    href: EPAPER_LINK,
  }
  return variant === 'mini' ? (
    <MiniEpaperButton {...buttonProps}>
      <EpaperIcon />
    </MiniEpaperButton>
  ) : (
    <DefaultEpaperButton {...buttonProps}>
      e-paper
      <EpaperIcon />
    </DefaultEpaperButton>
  )
}

EpaperButton.propTypes = {
  className: PropTypes.string,
  variant: PropTypes.string,
  checkAccess: PropTypes.bool,
}

export default EpaperButton
