import Link from 'components/Link'
import SvgLogo from 'public/assets/svg/scmp-flag.svg'

const ScmpLogo = ({ className }) => {
  return (
    <Link href="/" className={className} title={'SCMP Logo'}>
      <SvgLogo />
    </Link>
  )
}

export default ScmpLogo
