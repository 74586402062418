import cloneDeep from 'lodash/cloneDeep'
import get from 'lodash/get'

const name = 'loginState'

const defaultState = {
  loginPopupState: false,
  loginedState: null,
  triggerPoint: 'header',
}

const initialState = cloneDeep(defaultState)

// Please re-save storeHandler when you add new reducer
function reducer(state = initialState, { action, payload } = {}) {
  switch (action) {
    case 'UpdateLoginPopupState': {
      state.loginPopupState = get(payload, 'loginPopupState', false)
      state.triggerPoint = get(payload, 'triggerPoint', defaultState.triggerPoint)
      return {
        ...state,
      }
    }
    case 'UpdateloginedState': {
      state.loginedState = get(payload, 'loginedState', false)
      return {
        ...state,
      }
    }
    default: {
      return state
    }
  }
}

export default {
  name,
  initialState,
  defaultState,
  reducer,
}
