import * as scmpAccount from '@product/scmp-account-sdk'
import { setVariables } from 'lib/gtm'
import get from 'lodash/get'
import isEmpty from 'lodash/isEmpty'
import { useEffect } from 'react'
import { useStore } from 'store'
import styled from 'styled-components'

import {
  USER_PROFILE_PIC_PREFIX,
  CENTRALIZE_LOGIN_PROTOCOL,
  CENTRALIZE_LOGIN_HOST,
} from '../../config/app.config'

const AvatarContainer = styled.div`
  width: 100%;
  height: 100%;
`

const Avatar = ({ containerId, className }) => {
  const { loginState, userState, rosettaState } = useStore()
  const isYPSubscriber = get(rosettaState, 'state.subscriptionStatus.isYPSubscriber', false)
  const userInfo = get(userState, 'state.userInfo')
  const logined = get(loginState, 'state.loginedState')

  useEffect(() => {
    if (typeof window !== 'undefined') {
      if (userInfo !== null) {
        let userPicId = ''
        let userPicUrl = ''
        let avatarOptions = {}
        if (!isEmpty(userInfo)) {
          if (get(userInfo, 'upic', '')) {
            userPicId = get(userInfo, 'upic', '')
          } else if (get(userInfo, 'userPic', '')) {
            userPicId = get(userInfo, 'userPic', '')
          }
          if (!isEmpty(userPicId)) {
            userPicUrl = `${USER_PROFILE_PIC_PREFIX}${userPicId}`
          }
          avatarOptions.userPic = userPicUrl
          avatarOptions.lvl = get(userInfo, 'lvl', [])
          avatarOptions.userLevel = get(userInfo, 'userLevel', [])
          avatarOptions.level = get(userInfo, 'level', [])
          avatarOptions.subscriberState = false
          avatarOptions.isYPsubscriber = isYPSubscriber
        } else {
          avatarOptions.userPic =
            'data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iNDgiIGhlaWdodD0iNDgiIHZpZXdCb3g9IjAgMCA0OCA0OCIgZmlsbD0ibm9uZSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj4KPGNpcmNsZSBjeD0iMjQiIGN5PSIyNCIgcj0iMjMuMjUiIGZpbGw9IndoaXRlIiBzdHJva2U9ImJsYWNrIiBzdHJva2Utd2lkdGg9IjEuNSIvPgo8cGF0aCBmaWxsLXJ1bGU9ImV2ZW5vZGQiIGNsaXAtcnVsZT0iZXZlbm9kZCIgZD0iTTcuNTA5NzcgNDEuNDM3NEM3LjgwNjM2IDMyLjU4NTIgMTUuMDc1OSAyNS41IDI0LjAwMDQgMjUuNUMzMi45MjQ4IDI1LjUgNDAuMTk0NCAzMi41ODUyIDQwLjQ5MDkgNDEuNDM3NEMzNi4xOTA5IDQ1LjUwNTQgMzAuMzg2OSA0OCAyNC4wMDA0IDQ4QzE3LjYxMzggNDggMTEuODA5OCA0NS41MDU0IDcuNTA5NzcgNDEuNDM3NFpNMjQuMDAwNCAyNS41QzE5LjAyOTggMjUuNSAxNS4wMDA0IDIxLjQ3MDYgMTUuMDAwNCAxNi41QzE1LjAwMDQgMTEuNTI5NCAxOS4wMjk4IDcuNSAyNC4wMDA0IDcuNUMyOC45NzA5IDcuNSAzMy4wMDA0IDExLjUyOTQgMzMuMDAwNCAxNi41QzMzLjAwMDQgMjEuNDcwNiAyOC45NzA5IDI1LjUgMjQuMDAwNCAyNS41WiIgZmlsbD0iYmxhY2siLz4KPGNpcmNsZSBjeD0iMjQiIGN5PSIxNi41IiByPSIxMC41IiBzdHJva2U9IndoaXRlIiBzdHJva2Utd2lkdGg9IjMiLz4KPC9zdmc+Cg=='
        }
        scmpAccount.initAvatarWidget(containerId, avatarOptions)
      }
    }
    return () => {
      scmpAccount.destroyAvatarWidget(containerId)
    }
  }, [containerId, userInfo, isYPSubscriber])

  useEffect(() => {
    if (logined !== null) {
      scmpAccount.on(scmpAccount.EVENT.AVATAR_CLICKED, () => {
        // add GA event for click the Avatar
        setVariables({
          event: 'tracking',
          tracking: {
            category: 'Login',
            action: 'Login/Click',
            label: `${window.location.href}`,
          },
        })
        if (logined) {
          window.location.href = `${CENTRALIZE_LOGIN_PROTOCOL}://${CENTRALIZE_LOGIN_HOST}/settings?destination=${window.location.href}`
        } else {
          loginState.dispatch({
            action: 'UpdateLoginPopupState',
            payload: { loginPopupState: true },
          })
        }
      })
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [logined])

  return <AvatarContainer id={containerId} className={className} />
}

export default Avatar
