import RosettaSDK from '@product/rosetta-sdk'
import Cookies from 'js-cookie'
import _get from 'lodash/get'
import _includes from 'lodash/includes'
import _isEmpty from 'lodash/isEmpty'

import {
  CENTRALIZE_LOGIN_PROTOCOL,
  CENTRALIZE_LOGIN_HOST,
  SUBSCRIBE_PROTOCOL,
  SUBSCRIBE_HOST,
} from '../config/app.config'

const CENTRALIZE_LOGIN_ENDPOINTS = {
  SIGN_IN: '/centralize/signin',
  REGISTER: '/centralize/register',
}

export const getLoginUrl = (article) => {
  const host =
    location.protocol + '//' + location.hostname + (location.port ? ':' + location.port : '')
  const registerEndPoint = encodeURIComponent(
    host + CENTRALIZE_LOGIN_ENDPOINTS.REGISTER + '?email=',
  )
  const loginEndPoint = encodeURIComponent(host + CENTRALIZE_LOGIN_ENDPOINTS.SIGN_IN + '?nonce=')
  const pathname = _get(article, 'urlAlias', location.pathname)
  const destination = encodeURIComponent(
    [location.protocol, '//', location.host, pathname].join(''),
  )
  // tslint:disable-next-line:max-line-length
  const path = `login?callback_url_signin=${loginEndPoint}&callback_url_register=${registerEndPoint}&destination=${destination}`
  return `${CENTRALIZE_LOGIN_PROTOCOL}://${CENTRALIZE_LOGIN_HOST}/${path}`
}

export const getSubscribeUrl = (article) => {
  const pathname = _get(article, 'urlAlias', location.pathname)
  const destination = encodeURIComponent(
    [location.protocol, '//', location.host, pathname].join(''),
  )
  return `${SUBSCRIBE_PROTOCOL}://${SUBSCRIBE_HOST}/yp?destination=${destination}`
}

const removeMeterOpenBodyClass = () => {
  const tp = window.tp || []
  if (!_isEmpty(tp)) {
    const elBody = document.querySelector('body')
    if (elBody) {
      if (elBody.classList.contains('offer-meter-open')) {
        tp.offer.close()
        elBody.classList.remove('offer-meter-open')
      }
    }
  }
}

export const injectPaywall = () => {
  removeMeterOpenBodyClass()

  const activeArticles = document.querySelectorAll('.active-article')

  // due to bug when same article displays twice in perpetual scroll, we check for multiple elements..
  for (const activeArticle of activeArticles) {
    const wallStatus = activeArticle.querySelector('.piano-metering__paywall-container')

    if (wallStatus) {
      if (
        !wallStatus.classList.contains('paywall-is-processed') &&
        !wallStatus.classList.contains('paywall-container')
      ) {
        activeArticle.classList.add('paywall-is-processing')
        const activeMeterContainer = document.querySelector(
          '.paywall-is-processing .piano-metering__paywall-container',
        )

        if (activeMeterContainer) {
          activeMeterContainer.classList.add('paywall-container')

          if (RosettaSDK.isReady()) {
            RosettaSDK.execute()
          }
        }
      }
    }
  }
}

export const isSubscriber = (subscriptionStatus) => {
  if (subscriptionStatus !== null) {
    const { isYPSubscriber, isInternalStaff, isVipExclusive } = subscriptionStatus
    return isYPSubscriber === true || isInternalStaff === true || isVipExclusive === true
  }
  return false
}

export const getUserRole = (subscriptionStatus) => {
  const REGISTER_TYPE = {
    EMAIL: 'email',
    FACEBOOK: 'facebook',
    GOOGLE: 'google',
    GOOGLE_GSUITE: 'google:gsuite',
    GOOGLE_YOLO: 'google:yolo',
    APPLE: 'apple',
    PIANO_SWG: 'piano:swg',
    PIANO_EMAIL: 'piano:email',
  }

  const TRACKING_USER_ROLE = {
    ANONYMOUS_USER: 'anonymous user',
    PASSWORDLESS_USER: 'authenticated user - Register (passwordless)',
    EMAIL: 'authenticated user - Register (Email)',
    FACEBOOK: 'authenticated user - Register (Facebook)',
    GOOGLE: 'authenticated user - Register (Google)',
    GOOGLE_YOLO: 'authenticated user - Register (Google one-tap)',
    APPLE: 'authenticated user - Register (Apple)',
    SUBSCRIBER_FACEBOOK: 'Subscriber (Facebook)',
    SUBSCRIBER_GOOGLE: 'Subscriber (Google)',
    SUBSCRIBER_EMAIL: 'Subscriber (Email)',
    SUBSCRIBER_GOOGLE_YOLO: 'Subscriber (Google one-tap)',
    SUBSCRIBER_APPLE: 'Subscriber (Apple)',
    NL_FACEBOOK: 'authenticated user - Register (NL-Facebook)',
    NL_GOOGLE: 'authenticated user - Register (NL-Google)',
    NL_EMAIL: 'authenticated user - Register (NL-Email)',
    CHURN_FACEBOOK: 'Churn (Facebook)',
    CHURN_GOOGLE: 'Churn (Google)',
    CHURN_GOOGLE_YOLO: 'Churn (Google one-tap)',
    CHURN_EMAIL: 'Churn (Email)',
    CHURN_APPLE: 'Churn (Apple)',
  }

  const user = JSON.parse(Cookies.get('scmp_user') || '{}')
  const source = _get(user, 'source', '')
  const isLoggedInUser = !_isEmpty(user)
  const hasPianoJwt = !_isEmpty(Cookies.get('scmp_pat'))

  if (isLoggedInUser === false) {
    // for passwordless users, they should have scmp_pat cookie but not scmp_user cookie
    return hasPianoJwt === true
      ? TRACKING_USER_ROLE.PASSWORDLESS_USER
      : TRACKING_USER_ROLE.ANONYMOUS_USER
  }

  // handling for newletter user role
  if (_includes(source, 'nl:')) {
    if (_includes(source, 'facebook')) {
      return TRACKING_USER_ROLE.NL_FACEBOOK
    }
    if (_includes(source, 'google')) {
      return TRACKING_USER_ROLE.NL_GOOGLE
    }
    return TRACKING_USER_ROLE.NL_EMAIL
  }

  let isSubscriber = false
  let isChurnSubscriber = false
  if (!_isEmpty(subscriptionStatus)) {
    const {
      isYPChurned,
      isSCMPChurned,
      isYPSubscriber,
      isSCMPSubscriber,
      isInternalStaff,
      isVipExclusive,
    } = subscriptionStatus
    isSubscriber = isYPSubscriber || isSCMPSubscriber || isInternalStaff || isVipExclusive
    isChurnSubscriber = isYPChurned || isSCMPChurned
  }

  switch (source) {
    case REGISTER_TYPE.EMAIL: {
      if (isSubscriber) {
        return TRACKING_USER_ROLE.SUBSCRIBER_EMAIL
      } else if (isChurnSubscriber) {
        return TRACKING_USER_ROLE.CHURN_EMAIL
      } else {
        return TRACKING_USER_ROLE.EMAIL
      }
    }
    case REGISTER_TYPE.FACEBOOK: {
      if (isSubscriber) {
        return TRACKING_USER_ROLE.SUBSCRIBER_FACEBOOK
      } else if (isChurnSubscriber) {
        return TRACKING_USER_ROLE.CHURN_FACEBOOK
      } else {
        return TRACKING_USER_ROLE.FACEBOOK
      }
    }
    case REGISTER_TYPE.GOOGLE: {
      if (isSubscriber) {
        return TRACKING_USER_ROLE.SUBSCRIBER_GOOGLE
      } else if (isChurnSubscriber) {
        return TRACKING_USER_ROLE.CHURN_GOOGLE
      } else {
        return TRACKING_USER_ROLE.GOOGLE
      }
    }
    case REGISTER_TYPE.GOOGLE_GSUITE: {
      if (isSubscriber) {
        return TRACKING_USER_ROLE.SUBSCRIBER_GOOGLE
      } else if (isChurnSubscriber) {
        return TRACKING_USER_ROLE.CHURN_GOOGLE
      } else {
        return TRACKING_USER_ROLE.GOOGLE
      }
    }
    case REGISTER_TYPE.GOOGLE_YOLO: {
      if (isSubscriber) {
        return TRACKING_USER_ROLE.SUBSCRIBER_GOOGLE_YOLO
      } else if (isChurnSubscriber) {
        return TRACKING_USER_ROLE.CHURN_GOOGLE_YOLO
      } else {
        return TRACKING_USER_ROLE.GOOGLE_YOLO
      }
    }
    case REGISTER_TYPE.APPLE: {
      if (isSubscriber) {
        return TRACKING_USER_ROLE.SUBSCRIBER_APPLE
      } else if (isChurnSubscriber) {
        return TRACKING_USER_ROLE.CHURN_APPLE
      } else {
        return TRACKING_USER_ROLE.APPLE
      }
    }
    case REGISTER_TYPE.PIANO_EMAIL: {
      if (isSubscriber) {
        return TRACKING_USER_ROLE.SUBSCRIBER_EMAIL
      } else if (isChurnSubscriber) {
        return TRACKING_USER_ROLE.CHURN_EMAIL
      } else {
        return TRACKING_USER_ROLE.EMAIL
      }
    }
    case REGISTER_TYPE.PIANO_SWG: {
      if (isSubscriber) {
        return TRACKING_USER_ROLE.SUBSCRIBER_EMAIL
      } else if (isChurnSubscriber) {
        return TRACKING_USER_ROLE.CHURN_EMAIL
      } else {
        return TRACKING_USER_ROLE.EMAIL
      }
    }
    default: {
      if (isSubscriber) {
        return TRACKING_USER_ROLE.SUBSCRIBER_EMAIL
      } else if (isChurnSubscriber) {
        return TRACKING_USER_ROLE.CHURN_EMAIL
      } else {
        return TRACKING_USER_ROLE.EMAIL
      }
    }
  }
}
