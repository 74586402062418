import Link from 'components/Link'
import PropTypes from 'prop-types'
import LogoMinus from 'public/assets/svg/minus.svg'
import LogoPlus from 'public/assets/svg/plus.svg'
import { useState } from 'react'
import styled from 'styled-components'
import { remCalc, fontFamily, respondTo } from 'styles/mixins'

const StyledWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`

const StyledLink = styled.a`
  cursor: pointer;
  display: flex;
  width: ${remCalc(20)};
  height: ${remCalc(20)};
  align-items: center;
  justify-content: center;
  ${respondTo.mdAndUp`
    display: none;
  `}
`

const StyledHeadingLink = styled.a`
  cursor: pointer;
  color: inherit;
  text-decoration: none;
`

const StyledHeading = styled.h4`
  text-transform: uppercase;
  font-family: ${fontFamily.gothic};
  font-weight: bold;
  font-size: ${remCalc(20)};
  line-height: ${remCalc(28)};
  margin: 0;
  ${respondTo.smAndDown`
    font-size: ${remCalc(28)};
  `}
`

const FooterColumnHeading = ({ className, headerItem, onToggleCallback, itemOnClick }) => {
  const [toggle, setToggle] = useState(true)
  const { text, href = '', entityUuid = '' } = headerItem

  const onToggle = () => {
    setToggle(!toggle)
    onToggleCallback(toggle)
  }

  const itemOnClickHandler = (next) => {
    itemOnClick(entityUuid, next)
  }

  return (
    <StyledWrapper className={className}>
      {text && href && (
        <StyledHeading>
          <Link href={href} routerMiddleware={itemOnClickHandler}>
            <StyledHeadingLink>{text}</StyledHeadingLink>
          </Link>
        </StyledHeading>
      )}
      {text && !href && (
        <StyledHeading>
          <StyledHeadingLink>{text}</StyledHeadingLink>
        </StyledHeading>
      )}
      <StyledLink onClick={onToggle}>{toggle ? <LogoPlus /> : <LogoMinus />}</StyledLink>
    </StyledWrapper>
  )
}

FooterColumnHeading.defaultProps = {
  onToggleCallback: () => {},
}

FooterColumnHeading.propTypes = {
  headerItem: PropTypes.object.isRequired,
  onToggleCallback: PropTypes.func,
  itemOnClick: PropTypes.func,
}

export default FooterColumnHeading
