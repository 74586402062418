import cloneDeep from 'lodash/assign'

const defaultState = {}
const initialState = cloneDeep(defaultState)

// Please re-save storeHandler when you add new reducer
function reducer(state = initialState, payload) {
  return {
    ...state,
    ...payload,
  }
}

export default {
  name: 'externalScriptsState',
  initialState,
  defaultState,
  reducer,
}
