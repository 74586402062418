import { format } from 'date-fns'
import { fullUrl } from 'lib/formatter/url'
import get from 'lodash/get'
import isEmpty from 'lodash/isEmpty'
import map from 'lodash/map'

// GTM Custom Dimension Variables Schema:
// page: {
//   // 1 User Role
//   // 2
//   publishedDate: null,
//   // 3
//   authors: null,
//   // 4
//   sections: null,
//   // 5
//   topics: null,
//   // 8
//   webHeadline: null,
//   // 9
//   socialHeadline: null,
//   // 10
//   printHeadline: null,
//   // 11
//   articleID: null,
//   // 12
//   searchKeyword: null,
//   // 13
//   sponsor: null,
//   // 15
//   scmpWriter: null,
//   // 16
//   scmpCopyright: null,
//   // 17
//   primarySection: null,
//   // 23
//   topicID: null,
//   // 24
//   sourceTracking: null,
//   // 27
//   contentType: null,
//   // 28
//   articleType: null,
//   // 34 Drupal UID
//   // 36 Canonical URL
//   canonicalURL: null,
//   // 42 scmp
//   // 48 oneplusXUid
// },

function variablesMapping(entityType, data) {
  switch (entityType) {
    case 'article': {
      const {
        entityId,
        printHeadline,
        publishedDate,
        authors,
        types,
        ypTopics,
        sections,
        headline,
        socialHeadline,
        urlAlias,
      } = data
      return {
        page: {
          publishedDate: format(publishedDate ?? new Date(), 'dd MMM yyyy'),
          authors: map(authors, 'name').join(', '),
          sections: map(get(sections, '[0]'), 'name').join(', '),
          topics: map(ypTopics, 'name').join(', '),
          webHeadline: headline,
          socialHeadline,
          printHeadline,
          articleID: entityId,
          searchKeyword: null,
          sponsor: null,
          scmpWriter: null,
          scmpCopyright: null,
          primarySection: get(sections, '[0][0].name'),
          topicID: map(ypTopics, 'entityId').join(', '),
          sourceTracking: null,
          contentType: 'Article',
          articleType: map(get(types, '[0]'), 'name').join(', '),
          canonicalURL: fullUrl(urlAlias),
          page: 'Article',
        },
      }
    }
    case 'section': {
      const { name, urlAlias } = data
      return {
        page: {
          sections: name,
          primarySection: name,
          contentType: 'Section',
          canonicalURL: fullUrl(urlAlias),
        },
      }
    }
    case 'topic': {
      const { name, urlAlias, entityId } = data
      return {
        page: {
          topics: name,
          topicID: entityId,
          contentType: 'Topic',
          canonicalURL: fullUrl(urlAlias),
        },
      }
    }
    case 'page': {
      const { headline, urlAlias } = data
      return {
        page: {
          webHeadline: headline,
          contentType: 'Page',
          canonicalURL: fullUrl(urlAlias),
        },
      }
    }
    default: {
      return {}
    }
  }
}

/**
 * Deprecated, use dispatchSetVariablesEvent() instead
 */
function setVariables(dataLayerVariables) {
  dispatchSetVariablesEvent(dataLayerVariables)
}

function dispatchSetVariablesEvent(eventData) {
  if (typeof window !== 'undefined' && !isEmpty(eventData)) {
    window.document.dispatchEvent(new CustomEvent('set_variables', { detail: eventData }))
  }
}

function dispatchResetVariablesEvent() {
  if (typeof window !== 'undefined') {
    window.document.dispatchEvent(new Event('reset_variables'))
  }
}

function trackEvent(eventData) {
  if (typeof window !== 'undefined' && !isEmpty(eventData)) {
    // replace with current url, usually the event is fired from an iframe
    if (get(eventData, 'label', '') === '__url__') {
      eventData.label = window.location.href
    }

    window.dataLayer = window.dataLayer || []
    window.dataLayer.push({
      event: 'tracking',
      tracking: eventData,
    })
  }
}

function pushVirtualPageview() {
  if (typeof window !== 'undefined') {
    window.dataLayer = window.dataLayer || []
    window.dataLayer.push({ event: 'VirtualPageview' })
  }
}

export {
  setVariables,
  trackEvent,
  pushVirtualPageview,
  variablesMapping,
  dispatchSetVariablesEvent,
  dispatchResetVariablesEvent,
}
