import { sections } from 'config/section.config'
import find from 'lodash/find'
import get from 'lodash/get'
import startsWith from 'lodash/startsWith'
import { useRouter } from 'next/router'
import PropTypes from 'prop-types'
import React from 'react'

import { APP_PREFIX } from '../../config/app.config'

const Link = (props) => {
  const {
    href,
    href: { pathname = href, query = {} } = {},
    as,
    children,
    routerMiddleware,
    className,
    title,
    isDisabledClientRoute = true,
  } = props
  const router = useRouter()

  const mappedQuery = {
    ...query,
    routerSide: 'client',
  }

  const hrefOverride = {
    query: mappedQuery,
    ...{
      pathname:
        startsWith(pathname, '/') && !startsWith(pathname, '/_render')
          ? `/_render${pathname}`
          : pathname,
    },
    ...(pathname === '/' ? { pathname: '/home', theme: { type: 'static' } } : {}), // handle home
  }

  // Start of -- temp disable section client route
  const { entityUuid } = mappedQuery
  if (entityUuid) {
    const { path = false } = find(sections, { entityUuid }) || {}
    if (path) hrefOverride.pathname = path === '/' ? '/home' : path
  }
  // end of -- temp disable section client route

  const asOverride = as
    ? startsWith(as, '/') && !startsWith(as, '/yp')
      ? `${APP_PREFIX}${as}`
      : as
    : startsWith(pathname, '/') && !startsWith(pathname, '/yp')
      ? `${APP_PREFIX}${pathname}`
      : pathname

  const isExternalLink = startsWith(asOverride, 'http')

  const routeByMiddleware = (e) => {
    e.preventDefault()
    if (asOverride === '#') return
    if (isDisabledClientRoute) {
      isExternalLink ? window.open(asOverride, '_blank') : window.open(asOverride, '_self')
      return
    }
    if (routerMiddleware) {
      routerMiddleware(() =>
        isExternalLink ? window.open(asOverride, '_blank') : router.push(hrefOverride, asOverride),
      )
    } else {
      router.push(hrefOverride, asOverride)
    }
  }
  const type = get(children, 'type')
  return (
    <>
      {type ? (
        React.cloneElement(children, {
          ...{ onClick: (e) => routeByMiddleware(e), className },
          ...(type === 'a' || get(type, 'target') === 'a' ? { href: asOverride, title } : {}),
        })
      ) : isExternalLink ? (
        <a
          href={asOverride}
          target="_blank"
          rel="noopener noreferrer"
          title={title}
          className={className}
        >
          {children}
        </a>
      ) : (
        <a
          onClick={(e) => routeByMiddleware(e)}
          href={asOverride}
          className={className}
          title={title}
        >
          {children}
        </a>
      )}
    </>
  )
}

Link.propTypes = {
  href: PropTypes.oneOfType([PropTypes.string, PropTypes.object]).isRequired,
  title: PropTypes.string,
}

export default Link
