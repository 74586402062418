import FooterColumnHeading from 'components/Footer/FooterColumnHeading'
import FooterColumnList from 'components/Footer/FooterColumnList'
import PropTypes from 'prop-types'
import { useState } from 'react'
import styled from 'styled-components'
import { remCalc, respondTo } from 'styles/mixins'

const StyledFooterColumnHeading = styled(FooterColumnHeading)`
  margin-bottom: ${remCalc(25)};
  ${respondTo.mdAndUp`
    margin-bottom: ${remCalc(26)};
  `}
`

const StyledFooterColumnList = styled(FooterColumnList)`
  ${respondTo.mdAndUp`
    margin-top: 0;
  `}
  li:last-child {
    ${respondTo.smAndDown`
      margin-bottom: ${remCalc(35)};
    `}
  }
`

const FooterColumn = ({ className, headerItem, items = [], itemOnClick }) => {
  const [footerListExpanded, setFooterListExpanded] = useState(false)
  return (
    <div className={className}>
      <StyledFooterColumnHeading
        onToggleCallback={(toggle) => {
          setFooterListExpanded(toggle)
        }}
        headerItem={headerItem}
        itemOnClick={itemOnClick}
      />
      <StyledFooterColumnList expand={footerListExpanded} items={items} itemOnClick={itemOnClick} />
    </div>
  )
}

FooterColumn.propTypes = {
  headerItem: PropTypes.object.isRequired,
  items: PropTypes.array.isRequired,
  itemOnClick: PropTypes.func,
}

export default FooterColumn
