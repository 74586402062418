import gql from 'graphql-tag'

import { ARTICLE_FRAGMENT, ARTICLE_FRAGMENT_LITE } from '../fragments/cs'

// check path by url
const CHECK_PATH = gql`
  query checkPath($url: String!) {
    checkPath(url: $url) {
      ... on UrlPath {
        urlAlias
        item {
          ... on Article {
            entityUuid
            application {
              entityId
            }
            sections {
              name
              entityUuid
              entityId
            }
            authors {
              name
              entityUuid
            }
            published
            publishedDate
            updatedDate
            ypTopics {
              entityId
              name
            }
          }
          ... on Page {
            entityUuid
            application {
              entityId
            }
            published
          }
          ... on Author {
            entityUuid
            name
          }
          ... on YPTopic {
            entityUuid
            entityId
            name
          }
          ... on Section {
            name
            entityUuid
            entityId
            application {
              entityId
            }
          }
          ... on Video {
            entityUuid
            application {
              entityId
            }
            published
          }
          __typename
        }
      }
      ... on UrlRedirect {
        entityId
        entityUuid
        fromUrl
        toUrl
      }
      __typename
    }
  }
`

const GET_ARTICLES_BY_ENTITY_UUID = gql`
  query getArticlesByEntityUuids($entityUuids: [String], $limit: Int!) {
    articles(filter: { entityUuids: $entityUuids }, limit: $limit) {
      ...articleFragment
    }
  }
  ${ARTICLE_FRAGMENT}
`

const GET_ARTICLES_BY_ENTITY_UUID_LITE = gql`
  query getArticlesByEntityUuids($entityUuids: [String], $limit: Int!) {
    articles(filter: { entityUuids: $entityUuids }, limit: $limit) {
      ...articleFragmentLite
    }
  }
  ${ARTICLE_FRAGMENT_LITE}
`

const GET_ARTICLE_BY_ENTITY_UUID = gql`
  query getArticlesByEntityUuid($entityUuid: String) {
    article(filter: { entityUuid: $entityUuid }) {
      ...articleFragment
    }
  }
  ${ARTICLE_FRAGMENT}
`

const GET_ARTICLE_BY_ENTITY_UUID_LITE = gql`
  query getArticlesByEntityUuid($entityUuid: String) {
    article(filter: { entityUuid: $entityUuid }) {
      ...articleFragmentLite
    }
  }
  ${ARTICLE_FRAGMENT_LITE}
`

const GET_PAGE_BY_ENTITY_UUID = gql`
  query getPageByEntityUUID($entityUuid: String!) {
    page(filter: { entityUuid: $entityUuid }) {
      entityId
      entityUuid
      headline
      body
    }
  }
`

const GET_SECTION_BY_ENTITY_UUID = gql`
  query SectionByUuid($entityUuid: String!) {
    section(filter: { entityUuid: $entityUuid }) {
      entityId
      entityUuid
      name
      advertZone(version: 2)
      description
      urlAlias
      relatedNewsletterIds
      images {
        title
        url
        width
        height
        type
        square: style(filter: { style: "square" }) {
          url
        }
      }
    }
  }
`

const GET_TOPIC_BY_ENTITY_UUID = gql`
  query TopicById(
    $entityUuid: String!
    $excludeSectionIds: [String]
    $latestContentsLimit: Int = 10
    $after: String
    $itemLite: Boolean = true
  ) {
    ypTopic(filter: { entityUuid: $entityUuid }) {
      name
      entityId
      entityUuid
      description
      urlAlias
      latestContentsWithCursor(
        limit: $latestContentsLimit
        after: $after
        exclude: { sectionIds: $excludeSectionIds }
      ) {
        pageInfo {
          startCursor
          endCursor
          hasNextPage
        }
        items {
          entityId
          entityUuid
          headline
          socialHeadline
          subHeadline
          urlAlias
          updatedDate
          ... on Article {
            ...articleFragmentLite @include(if: $itemLite)
            ...articleFragment @skip(if: $itemLite)
          }
          sections {
            entityId
            name
            urlAlias
          }
          summary
        }
      }
    }
  }
  ${ARTICLE_FRAGMENT}
  ${ARTICLE_FRAGMENT_LITE}
`

const GET_QUEUE_ITEMS_UUID_BY_NAME = gql`
  query QueueByName(
    $name: String!
    $itemLimit: Int = 30
    $offset: Int = 0
    $excludeEntityUuids: [String] = []
  ) {
    queue(filter: { name: $name }) {
      items(limit: $itemLimit, offset: $offset, exclude: { entityUuids: $excludeEntityUuids }) {
        __typename
        ... on Article {
          entityUuid
        }
      }
    }
  }
`
const GET_QUEUE_ITEMS_ENTITY_BY_NAME = gql`
  query QueueByName(
    $name: String!
    $itemLimit: Int = 30
    $offset: Int = 0
    $excludeEntityUuids: [String] = []
  ) {
    queue(filter: { name: $name }) {
      items(limit: $itemLimit, offset: $offset, exclude: { entityUuids: $excludeEntityUuids }) {
        __typename
        ... on Article {
          ...articleFragment
        }
      }
    }
  }
  ${ARTICLE_FRAGMENT}
`

const GET_QUEUE_ITEMS_BY_NAME = gql`
  query QueueLessByName(
    $name: String!
    $itemLimit: Int = 30
    $offset: Int = 0
    $excludeEntityUuids: [String] = []
  ) {
    queue(filter: { name: $name }) {
      entityId
      name
      items(limit: $itemLimit, offset: $offset, exclude: { entityUuids: $excludeEntityUuids }) {
        __typename
        ... on Article {
          entityUuid
          entityId
          headline
          socialHeadline
          urlAlias
          images {
            title
            url
            width
            height
            type
            size_64x40: style(filter: { style: "64x40" }) {
              url
            }
            size_600x400: style(filter: { style: "crop_600x400" }) {
              url
            }
          }
          sections {
            entityId
            name
            urlAlias
          }
        }
        ... on YPTopic {
          name
          urlAlias
        }
      }
    }
  }
`

module.exports = {
  CHECK_PATH,
  GET_ARTICLES_BY_ENTITY_UUID,
  GET_ARTICLE_BY_ENTITY_UUID,
  GET_SECTION_BY_ENTITY_UUID,
  GET_TOPIC_BY_ENTITY_UUID,
  GET_QUEUE_ITEMS_UUID_BY_NAME,
  GET_QUEUE_ITEMS_ENTITY_BY_NAME,
  GET_QUEUE_ITEMS_BY_NAME,
  GET_PAGE_BY_ENTITY_UUID,
  GET_ARTICLE_BY_ENTITY_UUID_LITE,
  GET_ARTICLES_BY_ENTITY_UUID_LITE,
}
