import Link from 'components/Link'
import get from 'lodash/get'
import PropTypes from 'prop-types'
import DoubleArrow from 'public/assets/svg/double-arrow.svg'
import { useStore } from 'store'
import styled from 'styled-components'
import { remCalc, color, fontFamily, respondTo } from 'styles/mixins'

const Wrapper = styled.div`
  text-transform: uppercase;
  display: flex;
  align-items: center;
  color: ${color.black};
  position: relative;
  padding-left: ${remCalc(2)};
`

const SectionDepth1 = styled.span`
  font-family: ${fontFamily.gothic};
  font-weight: bold;
  font-size: ${remCalc(24)};
  line-height: ${remCalc(20)};
  letter-spacing: ${remCalc(1.1)};
  position: relative;

  .label {
    padding-left: ${remCalc(8)};

    ${respondTo.mdAndUp`
      font-size: ${remCalc(24)};
      letter-spacing: ${remCalc(1.2)};
      line-height: ${remCalc(28)};
      padding-left: ${remCalc(10)};
    `}
  }

  svg {
    margin-bottom: ${remCalc(-1)};
  }
`

const SectionDepth2 = styled.span`
  font-family: ${fontFamily.gothic};
  font-size: ${remCalc(16)};
  font-weight: 300;
  &:before {
    padding-left: ${remCalc(16)};
    content: '/ ';
  }

  ${respondTo.mdAndUp`
    letter-spacing: ${remCalc(0.8)};
    line-height: ${remCalc(28)};
  `}
`

const Breadcrumb = ({ hideDepth1 = false, hideDepth2 = false, className }) => {
  const { headerState, menuState } = useStore()
  const depth1Label = get(menuState, 'state.activeSection.depth1.name')
  const depth2Label = get(menuState, 'state.activeSection.depth2.name')
  const depth2Url = get(menuState, 'state.activeSection.depth2.path')

  return (
    <Wrapper className={className}>
      {!hideDepth1 && depth1Label && (
        <SectionDepth1
          onClick={() => headerState.dispatch({ type: 'ToggleMenu' })}
          className="item-clickable"
        >
          <DoubleArrow />
          <span className="label">{depth1Label}</span>
        </SectionDepth1>
      )}

      {!hideDepth2 && depth2Label && (
        <Link href={depth2Url} className="item-clickable">
          <SectionDepth2>{depth2Label}</SectionDepth2>
        </Link>
      )}
    </Wrapper>
  )
}

Breadcrumb.propTypes = {
  hideDepth1: PropTypes.bool,
  hideDepth2: PropTypes.bool,
}

export default Breadcrumb
