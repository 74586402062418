import { sections } from 'config/section.config'
import find from 'lodash/find'
import get from 'lodash/get'
import { getActiveSection } from 'utils/section'

const injectThemeState = ({ ctx: { query = {} }, router }) => {
  let outputState = {}
  const section = get(query, 'sectionUuid')
    ? find(sections, { entityUuid: query.sectionUuid })
    : find(sections, { path: router.asPath })
  if (get(section, 'entityUuid'))
    outputState = { activeTheme: get(getActiveSection(section.entityUuid), 'depth1') }
  return {
    themeState: outputState,
  }
}

const injectMenuState = ({ ctx: { query = {} }, router }) => {
  let outputState = {}
  const section = get(query, 'sectionUuid')
    ? find(sections, { entityUuid: query.sectionUuid })
    : find(sections, { path: router.asPath })
  if (get(section, 'entityUuid'))
    outputState = {
      entityUuid: section.entityUuid,
      activeSection: getActiveSection(section.entityUuid),
    }
  return {
    menuState: outputState,
  }
}

const injectStates = (appContext) => {
  return {
    ...injectThemeState(appContext),
    ...injectMenuState(appContext),
  }
}

module.exports = {
  injectThemeState,
  injectMenuState,
  injectStates,
}
