import DesktopHeader from 'components/Header/DesktopHeader'
import MobileHeader from 'components/Header/MobileHeader'
import GoogleOneTapSignIn from 'components/Login/googleOneTapSignIn'
import { sections } from 'config/section.config'
import { setVariables } from 'lib/gtm'
import filter from 'lodash/filter'
import find from 'lodash/find'
import get from 'lodash/get'
import map from 'lodash/map'
import remove from 'lodash/remove'
import startCase from 'lodash/startCase'
import throttle from 'lodash/throttle'
import toLower from 'lodash/toLower'
import { useRef, useState, useEffect, forwardRef, useImperativeHandle } from 'react'
import EventListener, { withOptions } from 'react-event-listener'
import { useStore } from 'store'
import styled, { ThemeProvider, css, keyframes, withTheme } from 'styled-components'
import { remCalc, respondTo } from 'styles/mixins'
import { getActiveSection } from 'utils/section'

const slideDown = keyframes`
  from {
    transform: translateY(${remCalc(-200)});
  }
  to {
    transform: translateY(0);
  }
`

const BaseHeader = styled.div`
  .item-clickable {
    cursor: pointer;
  }
  a {
    text-decoration: none;
    color: inherit;
  }
  width: 100%;
  top: 0;

  ${(props) =>
    ['article', 'static'].includes(props.theme.routerType) &&
    css`
      position: fixed;
      top: 0;
    `}

  ${(props) =>
    props.theme.scrollableWrapperMode &&
    css`
      z-index: 1;
    `}
`

const StyleDesktopHeader = styled(BaseHeader)`
  ${respondTo.smAndDown`
    display: none;
  `}

  ${(props) =>
    props.theme.sticky.desktop
      ? css`
          position: fixed;
          animation: ${slideDown} linear 200ms forwards;
          z-index: 100;
          will-change: transform animation;
        `
      : ''}
  ${(props) =>
    props.theme.menuExpanded
      ? css`
          position: fixed;
          z-index: 10;
        `
      : ''}
`

const StyleMobileHeader = styled(BaseHeader)`
  ${respondTo.mdAndUp`
    display: none;
  `}

  ${(props) =>
    props.theme.sticky.mobile || props.theme.menuExpanded || props.theme.routerType === 'article'
      ? css`
          position: fixed;
          z-index: 100;
          will-change: transform animation;
          ${props.theme.menuExpanded || props.theme.routerType === 'article'
            ? css`
                overflow-y: hidden;
                transform: translateY(0);
              `
            : css`
                animation: ${slideDown} linear 200ms forwards;
              `}

          ${props.theme.menuExpanded &&
          css`
            height: 100vh;
          `}
        `
      : ''}
`

const Buffer = styled.div`
  height: ${(props) =>
    props.height.mobile && props.theme.sticky.mobile ? remCalc(props.height.mobile) : 'auto'};
  ${respondTo.mdAndUp`
    height: ${(props) =>
      props.height.desktop && props.theme.sticky.desktop ? remCalc(props.height.desktop) : 'auto'};
  `}
  ${(props) =>
    props.scrollableWrapperMode &&
    css`
      position: absolute;
    `}
`

const Header = forwardRef(({ switchTheme, pageComponent }, ref) => {
  const { menuState, headerState, themeState } = useStore()
  const menuExpanded = get(headerState, 'state.menuExpanded')
  const scrollableWrapperMode = get(headerState, 'state.scrollableWrapperMode')
  const theme = get(themeState, 'state.activeTheme')
  const isStaticPage = !!(pageComponent === 'PageView')
  const [sticky, setSticky] = useState({ desktop: false, mobile: false })
  const [scrollDir, setScrollDir] = useState('')
  const desktopRef = useRef()
  const mobileRef = useRef()
  const WindowPos = useRef(typeof window !== 'undefined' ? window : {})
  let throttleTimeout = null

  useEffect(() => {
    if (!scrollableWrapperMode) {
      handleSticky(throttleTimeout, sticky.desktop, sticky.mobile)
      stickyTest(sticky.desktop, sticky.mobile, get(WindowPos, 'current.scrollY', 0))
    }
  }, [throttleTimeout, sticky.desktop, sticky.mobile, scrollableWrapperMode])

  const menuStateValue = get(menuState, 'state', {})
  const entityUuid = get(menuStateValue, 'entityUuid', '1')
  const entityId = get(menuStateValue, 'entityId', '')
  const isParentSection =
    get(menuStateValue, 'entityUuid') === get(menuStateValue, 'activeSection.depth1.entityUuid') ||
    get(menuStateValue, 'entityUuid') === '0'
  const [headerTheme, setHeaderTheme] = useState({ ...theme, isParentSection })
  const [headerSection, setHeaderSection] = useState(getActiveSection(entityUuid))
  const headerDispatch = headerState.dispatch
  useEffect(() => {
    setHeaderTheme({ ...theme, isParentSection })
    setHeaderSection(getActiveSection(entityUuid))
    headerDispatch({ type: 'CloseMenu' })
  }, [theme, isParentSection, entityUuid, headerDispatch])

  const activeSection = headerSection

  const itemOnClick = (entityUuid, next = false, direct = false) => {
    if (next) {
      // for expanded menu
      if (!find(headerSection, { entityUuid }) && !direct) {
        // expand if menu didn't expand
        if (filter(sections, { parent: entityUuid }).length > 0) {
          // has child
          if (menuExpanded) {
            setHeaderTheme({
              ...theme,
              ...get(getActiveSection(entityUuid), 'depth1', {}),
              isParentSection,
            })
            setHeaderSection(getActiveSection(entityUuid))
          } else {
            handleCallback(entityUuid, next)
          }
        } else {
          handleCallback(entityUuid, next)
        }
      } else {
        // navigate if menu expanded
        handleCallback(entityUuid, next)
      }
    }
  }

  const handleCallback = (entityUuid, callback) => {
    const SectionGroup = remove(
      map(getActiveSection(entityUuid), (item) => startCase(toLower(get(item, 'name')))),
      (item) => !!item,
    ).join('/')
    const ec = get(find(sections, { entityUuid }), 'depth') === 1 ? 'Header' : 'Menu'
    setVariables({
      event: 'tracking',
      tracking: {
        category: ec,
        action: `${ec}/Click`,
        label: `${ec}/Click/${SectionGroup}`,
      },
      eventCallback: callback,
    })
  }

  const toggleExpandedMenu = () => {
    if (headerState.state.menuExpanded && theme.entityUuid !== headerTheme.entityUuid) {
      setHeaderTheme({ ...theme, isParentSection })
      setHeaderSection(getActiveSection(entityUuid))
    }
    headerState.dispatch({ type: 'ToggleMenu' })
    setVariables({
      event: 'tracking',
      tracking: {
        category: 'Menu',
        action: `Menu/${headerState.state.menuExpanded ? 'Open' : 'Close'}`,
        label: `Menu/${headerState.state.menuExpanded ? 'Open' : 'Close'}`,
      },
    })
  }
  //

  const switchSection = (entityUuid) => {
    menuState.dispatch({ type: 'UpdateSection', payload: { entityUuid } })
    switchTheme(entityUuid)
  }

  const stickyTest = (stickyDesktop, stickyMobile, scrollY, fast) => {
    const scrollPos = scrollY
    if (fast) {
      setSticky({
        desktop: !!(scrollPos > 0),
        mobile: !!(scrollPos > 0),
      })
    } else if (
      stickyDesktop !== !!(scrollPos > desktopRef.current.offsetHeight + 200) ||
      stickyMobile !== !!(scrollPos > mobileRef.current.offsetHeight + 200)
    ) {
      setSticky({
        desktop: !!(scrollPos > desktopRef.current.offsetHeight + 200),
        mobile: !!(scrollPos > mobileRef.current.offsetHeight + 200),
      })
    }
  }

  const handleSticky = (throttleTimeout) => {
    const pos = WindowPos.current.scrollY

    const throttleCallback = (_pos) => {
      if (window.scrollY !== pos) setScrollDir(window.scrollY >= _pos ? 'down' : 'up')
      throttleTimeout = null
    }

    if (throttleTimeout === null) throttleTimeout = setTimeout(() => throttleCallback(pos), 100)
  }

  const handleScroll = (throttleTimeout, stickyDesktop, stickyMobile) => {
    handleSticky(throttleTimeout)
    stickyTest(stickyDesktop, stickyMobile, WindowPos.current.scrollY)
  }

  useImperativeHandle(ref, () => ({
    parallaxSticky(scrollPos) {
      stickyTest(sticky.desktop, sticky.mobile, scrollPos.scrollTop, true)
    },
  }))

  const headerProps = {
    scrollDir: scrollDir,
    switchSection: switchSection,
    isParentSection: isParentSection,
    toggleExpandedMenu: toggleExpandedMenu,
    activeSection: activeSection,
    entityId,
    entityUuid,
    itemOnClick: itemOnClick,
  }

  return (
    <ThemeProvider
      theme={{
        sticky,
        menuExpanded,
        scrollDir,
        scrollableWrapperMode,
        ...headerTheme,
        isStaticPage,
      }}
    >
      <GoogleOneTapSignIn />
      {!scrollableWrapperMode && (
        <>
          <EventListener
            target="window"
            onResize={throttle(
              () => handleScroll(throttleTimeout, sticky.desktop, sticky.mobile),
              100,
            )}
            onScroll={withOptions(
              throttle(() => handleScroll(throttleTimeout, sticky.desktop, sticky.mobile), 100),
              { passive: true, capture: false },
            )}
          />
          <Buffer
            height={{
              desktop: get(desktopRef, 'current.offsetHeight'),
              mobile: get(mobileRef, 'current.offsetHeight'),
            }}
          />
        </>
      )}
      {scrollableWrapperMode && (
        <EventListener
          target="window"
          onResize={throttle(
            () => handleScroll(throttleTimeout, sticky.desktop, sticky.mobile),
            100,
          )}
        />
      )}
      <StyleDesktopHeader className={'desktop-header'} ref={desktopRef}>
        <DesktopHeader sticky={sticky.desktop} {...headerProps} />
      </StyleDesktopHeader>
      <StyleMobileHeader className={'mobile-header'} ref={mobileRef}>
        <MobileHeader sticky={sticky.mobile} {...headerProps} />
      </StyleMobileHeader>
    </ThemeProvider>
  )
})

export default withTheme(Header)
