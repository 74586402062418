import styled, { css } from 'styled-components'

const StyledBubblePath = styled.path`
  ${(props) =>
    props.background &&
    css`
      fill: ${props.background};
    `}
`

const StyledPath = styled.path`
  ${(props) =>
    props.theme.highlightColor &&
    css`
      fill: ${props.theme.highlightColor};
    `}
`

const ConversationLogo = ({ background = '#000' }) => {
  return (
    <svg width="24px" height="24px" viewBox="0 0 24 24">
      <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
        <g id="d-article-page" transform="translate(-257.000000, -1262.000000)">
          <g id="icon/quote" transform="translate(257.000000, 1262.000000)">
            <StyledBubblePath
              background={background}
              d="M9.324,16.9681699 C9.324,13.125905 12.952,10.0006895 17.412,10.0006895 C19.5853669,9.97525934 21.689746,10.7632075 23.312,12.2098419 C23.3759964,12.2697668 23.4670462,12.2911021 23.551,12.2658457 C23.6352374,12.2414112 23.7005099,12.1746162 23.723,12.0898336 C23.9043052,11.4081676 23.9973987,10.7060532 24,10.0006895 C24,4.48630933 18.617,-7.28306304e-14 12,-7.28306304e-14 C5.383,-7.28306304e-14 0,4.48630933 0,10.0006895 C0.035191263,12.4099316 1.0309186,14.7053746 2.766,16.3771292 L1.347,21.1084554 C1.2914585,21.2952617 1.34950559,21.4973193 1.49572493,21.6261558 C1.64194426,21.7549923 1.84969032,21.7871299 2.028,21.7084968 L7.5,19.2723288 C8.18199549,19.5007563 8.88124706,19.6739927 9.591,19.7903645 C9.68186048,19.805158 9.77352821,19.7690224 9.82985763,19.6962064 C9.88618704,19.6233904 9.89813872,19.525579 9.861,19.4413405 C9.50958368,18.6640543 9.32657678,17.8212102 9.324,16.9681699 Z M9.5,12.2508447 L6,12.2508447 C5.58578644,12.2508447 5.25,11.9150351 5.25,11.500793 C5.25,11.0865509 5.58578644,10.7507413 6,10.7507413 L9.5,10.7507413 C9.91421356,10.7507413 10.25,11.0865509 10.25,11.500793 C10.25,11.9150351 9.91421356,12.2508447 9.5,12.2508447 Z M16.5,7.7505344 L6,7.7505344 C5.58578644,7.7505344 5.25,7.4147248 5.25,7.00048268 C5.25,6.58624056 5.58578644,6.25043096 6,6.25043096 L16.5,6.25043096 C16.9142136,6.25043096 17.25,6.58624056 17.25,7.00048268 C17.25,7.4147248 16.9142136,7.7505344 16.5,7.7505344 L16.5,7.7505344 Z"
              id="Shape"
            />
            <StyledPath
              d="M17.412,11.500793 C13.779,11.500793 10.824,13.9529621 10.824,16.9681699 C10.824,20.4894127 14.902,23.2906059 19.28,22.2005307 L22.78,23.9486513 C22.958238,24.0363294 23.1714698,24.0101797 23.3232434,23.8820306 C23.4750171,23.7538814 23.5365466,23.5480381 23.48,23.3576105 L22.58,20.3574036 C23.4743031,19.4541602 23.983349,18.2391774 24,16.9681699 C24,13.9529621 21.045,11.500793 17.412,11.500793 Z"
              id="Path"
            ></StyledPath>
          </g>
        </g>
      </g>
    </svg>
  )
}

export default ConversationLogo
