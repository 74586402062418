import NavigationItemsDepth2 from 'components/Header/children/NavigationItemsDepth2'
import NavigationItemsDepth3 from 'components/Header/children/NavigationItemsDepth3'
import filter from 'lodash/filter'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import { remCalc, color, fontFamily, respondTo } from 'styles/mixins'

const Wrapper = styled.div`
  color: ${color.black};
`

const Content = styled.div`
  margin: auto;
`

const StyledNavigationItemsDepth2 = styled(NavigationItemsDepth2)`
  display: flex;
  color: ${color.black};
  font-family: ${fontFamily.gothic};
  font-size: ${remCalc(16)};
  font-weight: bold;
  letter-spacing: ${remCalc(0.7)};
  line-height: ${remCalc(19)};
  ${respondTo.mdAndDown`
    width: calc(100% * 0.95);
  `}
  > .item:not(:first-child) {
    padding-left: ${remCalc(16)};
  }
  .item {
    border-right: solid ${remCalc(1)} ${(props) => props.theme.bgColor};
    color: ${color.black};
    font-family: ${fontFamily.gothic};
    font-size: ${remCalc(16)};
    letter-spacing: ${remCalc(0.7)};
    line-height: ${remCalc(19)};
    ${(props) => props.isNested && `min-width: ${remCalc(120)};`}
    padding-right: ${remCalc(16)};
    padding-bottom: ${remCalc(10)};
    a {
      padding-bottom: ${remCalc(16)};
      display: block;
    }
    &:first-child {
      padding-left: 0;
    }
    &:last-child {
      border: none;
    }
  }
`

const StyledNavigationItemsDepth3 = styled(NavigationItemsDepth3)`
  font-weight: normal;
  .item {
    border: none;
    font-size: ${remCalc(15)};
    line-height: ${remCalc(18)};
    padding-bottom: ${remCalc(14)};
    letter-spacing: normal;
    font-family: ${fontFamily.roboto};
    text-transform: none;
    padding-left: 0;
    text-align: left;
    margin-left: 0;

    a {
      padding-bottom: 0;

      &:hover {
        text-decoration: underline;
      }
    }
  }
`

const StyledRow = styled.div`
  max-width: 86.5rem;
  margin: 0 auto;

  ${respondTo.mdAndDown`
    width: auto;
    padding: ${remCalc(0, 28)};
  `}
`

const StyledCol = styled.div`
  padding-left: 0;
  margin-left: ${remCalc(151)};
`

const Menu = ({ className, sections, activeSection, entityUuid, itemOnClick }) => {
  const sectionDepth2 = filter(sections, { depth: 2 })
  return (
    <Wrapper className={className}>
      <Content>
        <StyledRow>
          <StyledCol md={14} lg={14} mdOffset={2} lgOffset={2}>
            <StyledNavigationItemsDepth2
              isNested={true}
              section={sectionDepth2}
              sections={sections}
              activeSection={activeSection}
              entityUuid={entityUuid}
              itemOnClick={itemOnClick}
              nestedComponent={<StyledNavigationItemsDepth3 />}
              toggleable={false}
            />
          </StyledCol>
        </StyledRow>
      </Content>
    </Wrapper>
  )
}

Menu.propTypes = {
  sections: PropTypes.array.isRequired,
  activeSection: PropTypes.object.isRequired,
  entityUuid: PropTypes.string,
  itemOnClick: PropTypes.func.isRequired,
}

export default Menu
