import cloneDeep from 'lodash/assign'
import has from 'lodash/has'
import isEmpty from 'lodash/isEmpty'
import set from 'lodash/set'

const name = 'overlayState'

const defaultState = {
  show: null,
  options: {
    overlayType: false,
  },
}

const initialState = cloneDeep(defaultState)

// Please update the reducer counter in storeHandler when you add new reducer
function reducer(state = initialState, { type, options } = {}) {
  switch (type) {
    case 'toogleOverlay': {
      // lock html document when overlay is opened
      const isShown = !state.show
      if (typeof window !== 'undefined' && has(window, 'document')) {
        const htmlDom = window.document.querySelector('html')
        if (htmlDom instanceof window.Element) {
          if (isShown) {
            htmlDom.classList.add('lock')
          } else {
            htmlDom.classList.remove('lock')
          }
        }
      }

      if (!isEmpty(options)) {
        set(state, 'options', {
          ...state.options,
          ...options,
        })
      }

      return {
        ...state,
        show: isShown,
      }
    }
    default: {
      return state
    }
  }
}

export default {
  name,
  initialState,
  defaultState,
  reducer,
}
