import useFetchCommentCounts from 'hooks/hook-fetch-comment-counts'
import get from 'lodash/get'

const withCommentLogic = (WrappedComponent) => {
  const WithCommentLogic = (props) => {
    const { storyId } = props
    const counts = useFetchCommentCounts({
      storyIds: [storyId],
    })
    const commentCount = get(counts, '[0]', 0)
    const processedProps = {
      ...props,
      commentCount,
    }

    return storyId ? <WrappedComponent {...processedProps} /> : <></>
  }
  return WithCommentLogic
}

export default withCommentLogic
