import Link from 'components/Link'
import map from 'lodash/map'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import { remCalc, fontFamily, color, respondTo } from 'styles/mixins'

const StyledList = styled.ul`
  list-style: none;
  margin: 0;
  padding: 0;
  font-family: ${fontFamily.roboto};
  transition: max-height 0.6s ease-in-out;
  overflow: hidden;
  ${respondTo.smAndDown`
    max-height: ${(props) => (props.expand ? remCalc(400) : '0')};
  `}
`

const StyledListItem = styled.li`
  margin-bottom: ${remCalc(16)};
`

const StyledLink = styled(Link)`
  text-decoration: none;
  text-transform: uppercase;
  color: ${color.white};
  font-size: ${remCalc(14)};
  line-height: ${remCalc(16)};
  ${respondTo.smAndDown`
    font-size: ${remCalc(16)};
    line-height: ${remCalc(19)};
    font-weight: bold;
  `}
`

const FooterList = ({ className, items = [], expand, itemOnClick }) => {
  const listItems = map(items, (item, index) => {
    const itemOnClickHandler = (next) => {
      itemOnClick(item.entityUuid, next)
    }
    return (
      <StyledListItem key={index}>
        <StyledLink href={item.href} routerMiddleware={itemOnClickHandler}>
          {item.text}
        </StyledLink>
      </StyledListItem>
    )
  })

  return (
    <StyledList className={className} expand={expand}>
      {listItems}
    </StyledList>
  )
}

FooterList.propTypes = {
  expand: PropTypes.bool,
  items: PropTypes.array.isRequired,
  itemOnClick: PropTypes.func,
}

export default FooterList
