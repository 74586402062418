import { sections } from 'config/section.config'
import cloneDeep from 'lodash/cloneDeep'
import filter from 'lodash/filter'
import get from 'lodash/get'
import { getActiveSection } from 'utils/section'

const name = 'themeState'

const themes = filter(sections, { depth: 1 })

const defaultTheme = themes[0]

const defaultState = {
  activeTheme: defaultTheme,
}

const initialState = cloneDeep(defaultState)

// Please re-save storeHandler when you add new reducer
function reducer(state = initialState, { action, payload } = {}) {
  switch (action) {
    case 'UpdateTheme': {
      const entityUuid = get(payload, 'entityUuid')
      const activeSection = getActiveSection(entityUuid)
      state.activeTheme = {
        ...defaultTheme,
        ...get(activeSection, 'depth1', {}),
      }
      return {
        ...state,
      }
    }
    default: {
      return state
    }
  }
}

export default {
  name,
  initialState,
  defaultState,
  reducer,
}
