import Link from 'components/Link'
import get from 'lodash/get'
import styled from 'styled-components'
import { color } from 'styles/mixins'

const Wrapper = styled.div`
  width: 100%;
  height: 100%;
  color: ${color.black};
  line-height: 0;
  a {
    text-decoration: none;
    color: inherit;
  }
`

const GradientFrom = styled.stop.attrs((props) => ({
  stopColor: get(props, 'theme.LogoGradientFrom', color.white),
}))``

const GradientTo = styled.stop.attrs((props) => ({
  stopColor: get(props, 'theme.LogoGradientTo', color.white),
}))``

const RenderScmpLogo = () => (
  <svg width="69px" height="60px" viewBox="0 0 69 60">
    <defs>
      <linearGradient x1="50%" y1="-10.5193662%" x2="50%" y2="100%" id="linearGradient-1">
        <GradientFrom offset="0%"></GradientFrom>
        <GradientTo offset="100%"></GradientTo>
      </linearGradient>
    </defs>
    <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
      <path
        d="M67.9927007,6.5 L67.9927007,59 L0.503649635,59 L0.503649635,6.5 L67.9927007,6.5 Z M31.3349787,12.5206422 L6.33004254,12.5206422 L6.33110157,53.2201835 L31.3349787,53.2201835 L31.3349787,12.5206422 Z M62.1713201,12.3722222 L37.1726301,12.3722222 L37.1726301,53.1214715 L62.1713201,53.1214715 L62.1713201,12.3722222 Z"
        fill="url(#linearGradient-1)"
      ></path>
      <path
        d="M69,6 L69,60 L9.16133349e-13,60 L9.16133349e-13,6 L69,6 Z M67.5420106,7.44642857 L1.45750352,7.44642857 L1.45750352,58.5535714 L67.5420106,58.5535714 L67.5420106,7.44642857 Z M32.3133803,12.0267857 L32.3133803,53.0267857 L5.8320646,53.0267857 L5.8309671,12.0267857 L32.3133803,12.0267857 Z M63.1740303,11.8782143 L63.1740303,53.8782143 L36.6978871,53.8782143 L36.6978871,11.8782143 L63.1740303,11.8782143 Z M30.8553908,13.4732143 L7.28848944,13.4732143 L7.28970423,52.7678571 L30.8553908,52.7678571 L30.8553908,13.4732143 Z M61.7161268,13.3244732 L38.1555423,13.3244732 L38.1555423,52.6690179 L61.7161268,52.6690179 L61.7161268,13.3244732 Z"
        fill="#000000"
        fillRule="nonzero"
      ></path>
      <path
        d="M67.9927007,1 L67.9927007,53.5 L0.503649635,53.5 L0.503649635,1 L67.9927007,1 Z M31.3349787,7.0206422 L6.33004254,7.0206422 L6.33110157,47.7201835 L31.3349787,47.7201835 L31.3349787,7.0206422 Z M62.1713201,6.87222222 L37.1726301,6.87222222 L37.1726301,47.6214715 L62.1713201,47.6214715 L62.1713201,6.87222222 Z"
        fill="#FFFFFF"
      ></path>
      <path
        d="M69,0 L69,54 L9.16133349e-13,54 L9.16133349e-13,0 L69,0 Z M67.5420106,1.44642857 L1.45750352,1.44642857 L1.45750352,52.5535714 L67.5420106,52.5535714 L67.5420106,1.44642857 Z M32.3133803,6.02678571 L32.3133803,48.2142857 L5.8320646,48.2142857 L5.8309671,6.02678571 L32.3133803,6.02678571 Z M63.1740303,5.87821429 L63.1740303,48.115473 L36.6978871,48.115473 L36.6978871,5.87821429 L63.1740303,5.87821429 Z M30.8553908,7.47321429 L7.28848944,7.47321429 L7.28970423,46.7678571 L30.8553908,46.7678571 L30.8553908,7.47321429 Z M61.7161268,7.32447321 L38.1555423,7.32447321 L38.1555423,46.6690179 L61.7161268,46.6690179 L61.7161268,7.32447321 Z"
        fill="#000000"
        fillRule="nonzero"
      ></path>
    </g>
  </svg>
)

const ScmpHeader = ({ className }) => {
  return (
    <Wrapper className={className}>
      <Link href="http://scmp.com">
        <RenderScmpLogo />
      </Link>
    </Wrapper>
  )
}

export default ScmpHeader
