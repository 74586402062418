import classNames from 'classnames/bind'
import Link from 'components/Link'
import { setVariables } from 'lib/gtm'
import get from 'lodash/get'
import map from 'lodash/map'
import PropTypes from 'prop-types'
import { useMemo } from 'react'
import styled from 'styled-components'
import { remCalc, color, fontFamily } from 'styles/mixins'

const Wrapper = styled.nav`
  color: ${color.black};
  font-family: ${fontFamily.roboto};
  font-size: ${remCalc(16)};
  line-height: ${remCalc(19)};
  text-align: center;
  text-transform: uppercase;
  margin-bottom: ${remCalc(-2)};
  .item {
    margin: ${remCalc(0, 11)};
    ${(props) =>
      props.isNested &&
      `
      text-align: left;
      margin: 0;
    `}
  }
`

const NavigationItemsDepth3 = ({
  className,
  section = [],
  isNested = false,
  activeSection,
  itemOnClick,
}) => {
  const renderItems = useMemo(
    () =>
      map(section, (item) => {
        const itemOnClickHandler = (next) => {
          setVariables({
            event: 'GA4_tracking',
            category: 'menu',
            subcategory: 'section',
            action: 'click',
            customized_parameters: {
              destination: item.path,
            },
          })
          if (itemOnClick) {
            itemOnClick(item.entityUuid, next, true)
          } else {
            next()
          }
        }
        return (
          <div
            className={classNames('item', {
              active: get(activeSection, 'depth3.entityUuid') === item.entityUuid,
            })}
            key={`depth-${item.entityUuid}`}
          >
            <Link
              href={{
                pathname: get(item, 'render', '/_render/sectionpage'),
                query: { entityUuid: item.entityUuid },
              }}
              as={item.path}
              routerMiddleware={itemOnClickHandler}
            >
              {item.name}
            </Link>
          </div>
        )
      }),
    [section, activeSection, itemOnClick],
  )

  return (
    <Wrapper className={classNames(className)} isNested={isNested}>
      {renderItems}
    </Wrapper>
  )
}

NavigationItemsDepth3.propTypes = {
  section: PropTypes.array,
  activeSection: PropTypes.object,
  itemOnClick: PropTypes.func,
}

export default NavigationItemsDepth3
