import { setVariables } from 'lib/gtm'
import get from 'lodash/get'
import isEmpty from 'lodash/isEmpty'
import PropTypes from 'prop-types'
import { useEffect, useState } from 'react'
import { useInView } from 'react-intersection-observer'
import { useStore } from 'store'
import styled from 'styled-components'
import { fontFamily, remCalc, respondTo } from 'styles/mixins'
import { isSubscriber, getSubscribeUrl } from 'utils/rosetta'

const StyleSubscribeButton = styled.a`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: ${remCalc(20)} ${remCalc(58)};

  position: static;
  width: 134px;
  height: 48px;
  left: 0px;
  top: 0px;

  background: #ffffff;
  border: 2px solid #000000;
  box-sizing: border-box;
  box-shadow: 0px 4px 0px #000000;
  border-radius: 4px;

  font-family: ${fontFamily.roboto};
  font-style: normal;
  font-weight: bold;
  font-size: ${remCalc(16)};
  line-height: ${remCalc(19)};

  text-align: center;
  text-transform: uppercase;

  color: #000000;

  flex: none;
  order: 0;
  flex-grow: 0;
  margin: 0px 10px 0 0;

  ${respondTo.smAndDown`
    width: 106px;
    height: 40px;
    right: 48px;
    top: 0px;
    margin: 0px 8px;
  `}
`

const getUniversalAnalyticsEventActionName = ({ actionType, headerState }) => {
  const suffix = actionType === 'impression' ? '/Impression' : ''

  switch (headerState) {
    case 'expanded':
      return `Subscription Entry Point/Hamburger Menu${suffix}`
    case 'article':
      return `Subscription Entry Point/Article Page Masthead${suffix}`
    default:
      return `Subscription Entry Point/Masthead${suffix}`
  }
}

const getTriggerPoint = (headerState) => {
  switch (headerState) {
    case 'expanded':
      return 'hamburger menu'
    case 'article':
      return 'article masthead'
    default:
      return 'masthead'
  }
}

const SubscribeButton = ({ headerState, className }) => {
  const { headerState: storeHeaderState, rosettaState } = useStore()
  const subscriptionStatus = get(rosettaState, 'state.subscriptionStatus', null)
  const subscriberPhase = get(rosettaState, 'state.subscriberPhase', null)
  const headerEntityId = get(storeHeaderState, 'state.headerEntity.entityId', null)

  const [showSubscribe, setShowSubscribe] = useState(undefined)

  const [isImpressionEventTriggered, setIsImpressionEventTriggered] = useState(false)
  const { inView, ref } = useInView()

  useEffect(() => {
    if (isImpressionEventTriggered || !inView || subscriberPhase === null) {
      return
    }

    setVariables({
      event: 'tracking',
      tracking: {
        category: 'Subscription Entry Point',
        action: getUniversalAnalyticsEventActionName({
          actionType: 'impression',
          headerState,
        }),
        label: window.location.href,
      },
    })

    setVariables({
      event: 'GA4_tracking',
      category: 'subs',
      subcategory: 'entry_point',
      action: 'imp',
      customized_parameters: {
        ...(isEmpty(headerEntityId) ? {} : { article_id: headerEntityId }),
        call_to_action: 'subscribe',
        paywall_status: 'hard',
        subscriber_phase: subscriberPhase,
        trigger_point: getTriggerPoint(headerState),
      },
    })

    setIsImpressionEventTriggered(true)
  }, [headerState, inView, isImpressionEventTriggered, subscriberPhase])

  const clickButton = () => {
    setVariables({
      event: 'tracking',
      tracking: {
        category: 'Subscription Entry Point',
        action: getUniversalAnalyticsEventActionName({
          actionType: 'click',
          headerState,
        }),
        label: window.location.href,
      },
    })

    setVariables({
      event: 'GA4_tracking',
      category: 'subs',
      subcategory: 'entry_point',
      action: 'click',
      customized_parameters: {
        ...(isEmpty(headerEntityId) ? {} : { article_id: headerEntityId }),
        call_to_action: 'subscribe',
        paywall_status: 'hard',
        subscriber_phase: subscriberPhase,
        trigger_point: getTriggerPoint(headerState),
      },
    })
  }

  useEffect(() => {
    // wait until subscriptionStatus is not null
    if (subscriptionStatus !== null) {
      if (isSubscriber(subscriptionStatus) === true) {
        setShowSubscribe(false)
      } else {
        setShowSubscribe(true)
      }
    }
  }, [subscriptionStatus])

  return (
    showSubscribe === true && (
      <StyleSubscribeButton
        className={className}
        href={getSubscribeUrl()}
        onClick={clickButton}
        ref={ref}
      >
        Subscribe
      </StyleSubscribeButton>
    )
  )
}

SubscribeButton.propTypes = {
  headerState: PropTypes.string,
  className: PropTypes.string,
}

export default SubscribeButton
