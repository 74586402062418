import { sections as sectionList } from 'config/section.config'
import filter from 'lodash/filter'
import find from 'lodash/find'
import get from 'lodash/get'

export const getActiveSection = (articleEntityUuid) => {
  const depth3 = find(filter(sectionList, { depth: 3 }), { entityUuid: articleEntityUuid }) || {}
  const depth2 =
    find(filter(sectionList, { depth: 2 }), {
      entityUuid: get(depth3, 'parent', articleEntityUuid),
    }) || {}
  const depth1 =
    find(filter(sectionList, { depth: 1 }), {
      entityUuid: get(depth2, 'parent', articleEntityUuid),
    }) || {}
  return {
    depth1,
    depth2,
    depth3,
  }
}

export const getSectionByName = (name) => sectionList.find((section) => section.name === name)

export const containSection = (sections, sectionEntityId) => {
  if (!sectionEntityId) return

  return sections?.some((section) =>
    section.some((subSection) => subSection?.entityId === sectionEntityId),
  )
}

export const hasSparkSection = (sections) => {
  const sparkSectionId = getSectionByName('spark')?.entityId
  return sparkSectionId && containSection(sections, sparkSectionId)
}
