import color from './color'
import fontFamily from './font-family'
import noGutter from './no-gutter'
import remCalc from './rem-calc'
import respondTo from './respond-to'

module.exports = {
  fontFamily,
  remCalc,
  respondTo,
  color,
  noGutter,
}
