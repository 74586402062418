import { createGlobalStyle } from 'styled-components'
import { normalize } from 'styled-normalize'
import {color, fontFamily, respondTo, remCalc} from 'styles/mixins'

const GlobalStyle = createGlobalStyle`
  ${normalize}
  html, body {
    overflow-x: hidden;
  }

  html.lock {
    margin-right: ${remCalc(15)};
    overflow-y: hidden;
  }

  body {
  	font-family: ${fontFamily.roboto};
  	color: ${color['black']};
  	-webkit-text-size-adjust: 100%;
  	-webkit-font-smoothing: antialiased;
    min-height: 100vh;
    height: 100%;
    width: 100%;
  }

  .slick-track {
    width: max-content !important;
    margin: 0;
  }

  ${respondTo.mdAndUp`
    .hidden-md-up {
      display: none;
    }
  `}
  ${respondTo.smAndDown`
    .hidden-sm-down {
      display: none;
    }
  `}
`

export default GlobalStyle
