import ConversationLogo from 'components/Common/Logo/Conversation'
import withCommentCountLogic from 'hocs/comment/with-comment-count-logic'
import withCommentLayerLogic from 'hocs/comment/with-comment-layer-logic'
import flow from 'lodash/flow'
import styled from 'styled-components'
import { remCalc, fontFamily } from 'styles/mixins'

const StyledArticleCommentButtonWrapper = styled.div`
  cursor: pointer;
  display: flex;
  flex-direction: row;
  align-items: center;
`

const StyledCount = styled.span`
  font-weight: bold;

  font-size: ${remCalc(24)};
  line-height: ${remCalc(16)};

  font-family: ${fontFamily.gothic};

  margin-right: ${remCalc(10)};
`

const HeaderCommentButton = ({ className, clickHandler, commentCount }) => {
  return (
    <StyledArticleCommentButtonWrapper onClick={clickHandler} className={className}>
      {commentCount > 0 && <StyledCount>{commentCount}</StyledCount>}
      <ConversationLogo />
    </StyledArticleCommentButtonWrapper>
  )
}

export default flow([withCommentLayerLogic, withCommentCountLogic])(HeaderCommentButton)
