import useFetchSection from 'hooks/hook-fetch-section'
import localStorage from 'local-storage-fallback'
import get from 'lodash/get'
import SvgArrowRight from 'public/assets/svg/arrow_right_white.svg'
import KnowledgeLogotype from 'public/assets/svg/knowledge-logotype.svg'
import ScmpFlagWhiteOnly from 'public/assets/svg/scmp-flag-white-only.svg'
import { useEffect, useState } from 'react'
import styled from 'styled-components'
import { remCalc, respondTo, color, fontFamily } from 'styles/mixins'

import { SCMP_MAIN_SITE_DOMAIN } from '../../config/app.config'

const Container = styled.div`
  display: flex;
  transition: transform 0.3s ease-out;
  bottom: 0;
  transform: ${(props) => (props.showBanner ? 'translateY(0%)' : 'translateY(100%)')};
  position: fixed;
  width: 100%;
  justify-content: center;
  z-index: 20;
`

const Wrapper = styled.a`
  text-decoration: none;
  background-color: ${color.sunshiesyellowmuddy};
  width: 100%;
  height: ${remCalc(90)};
  border-top-left-radius: ${remCalc(45)};
  border-top-right-radius: ${remCalc(45)};
  border-bottom-right-radius: ${remCalc(0)};
  border-bottom-left-radius: ${remCalc(0)};
  display: flex;
  align-items: center;

  ${respondTo.mdAndUp`
    width: 80%;
    max-width: ${remCalc(1152)};
    height: ${remCalc(144)};
    border-top-left-radius: ${remCalc(96)};
    border-top-right-radius: ${remCalc(96)};
    border-bottom-right-radius: ${remCalc(96)};
    border-bottom-left-radius: ${remCalc(0)};
  `}
`

const StyledLeftIcon = styled.div`
  background-image: url(${(props) => props.leftIcon});
  background-repeat: no-repeat;
  background-position: center center;
  border-radius: 50%;
  background-size: contain;
  align-self: center;
  border: ${remCalc(2)} solid white;
  height: ${remCalc(68)};
  width: ${remCalc(68)};
  margin-left: ${remCalc(19)};

  ${respondTo.mdAndUp`
    border: ${remCalc(3.2)} solid white;
    height: ${remCalc(134)};
    width: ${remCalc(134)};
    margin-left: ${remCalc(60)};
  `}
`

const StyledBodyWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: start;
  padding-left: ${remCalc(11)};
  padding-top: ${remCalc(16)};
  flex-grow: 1;
  width: 1%;
  max-height: ${remCalc(90)};
  padding-bottom: ${remCalc(9)};

  ${respondTo.mdAndUp`
    max-height: ${remCalc(144)};
    padding-left: ${remCalc(40)};
    padding-top: ${remCalc(28)};
  `}
`

const StyledKnowledgeIconWrapper = styled.div`
  display: flex;
  align-items: center;
`

const StyledScmpFlagWhiteOnly = styled(ScmpFlagWhiteOnly)`
  width: ${remCalc(16)};
  height: ${remCalc(12)};
  ${respondTo.mdAndUp`
    width: ${remCalc(26)};
    height: ${remCalc(19)};
  `}
`

const StyledKnowledgeLogotype = styled(KnowledgeLogotype)`
  width: ${remCalc(72)};
  height: ${remCalc(7)};
  padding-left: ${remCalc(8)};
  ${respondTo.mdAndUp`
    width: ${remCalc(116)};
    height: ${remCalc(11)};
    padding-left: ${remCalc(12)};
  `}
`

const StyledTitle = styled.div`
  color: ${color.black};
  font-family: ${fontFamily.gothic};
  font-weight: bold;
  letter-spacing: 0;
  padding-top: ${remCalc(6.5)};
  line-height: ${remCalc(21)};
  font-size: ${remCalc(18)};
  white-space: normal;
  overflow: hidden;
  text-overflow: ellipsis;

  ${respondTo.mdAndUp`
    padding-top: ${remCalc(14)};
    line-height: ${remCalc(41.6)};
    font-size: ${remCalc(32)};
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  `}
`

const StyledLeanMore = styled.a`
  text-decoration: none;
  display: none;
  ${respondTo.mdAndUp`
    display: block;
    padding-top: ${remCalc(7)};
    color: ${color.black};
    font-family: ${fontFamily.roboto};;
    font-size: ${remCalc(14.4)};
    letter-spacing: ${remCalc(0.26)};
    line-height: ${remCalc(17)};
  `}
`

const StyledRightArrowButton = styled.div`
  border-radius: 50%;
  background-color: ${color.avocado};
  display: flex;
  align-items: center;
  justify-content: center;
  margin-right: ${remCalc(17)};
  width: ${remCalc(56)};
  height: ${remCalc(56)};

  ${respondTo.mdAndUp`
    margin-right: ${remCalc(35)};
    width: ${remCalc(90)};
    height: ${remCalc(90)};
  `}
`

const StyledSvgArrowRight = styled(SvgArrowRight)`
  color: ${color.white};
  width: ${remCalc(26)};
  height: ${remCalc(20)};

  ${respondTo.mdAndUp`
    width: ${remCalc(42)};
    height: ${remCalc(32)};
  `}
`

const showOnceKey = 'modal_knowledge_show_once'

const ModalKnowledge = () => {
  const context = useFetchSection('23ca9131-ff43-455c-adca-9ab1f4a31dbc') // section /knowledge/topics/asian-festivals

  const [show, setShow] = useState(false)
  const [showBanner, setShowBanner] = useState(false)

  const leftIcon = get(context, 'images[0].square.url')
  const title = 'What are Asia\'s most famous festivals?'
  const urlAlias = `${SCMP_MAIN_SITE_DOMAIN}${get(context, 'urlAlias')}`

  useEffect(() => {
    const shouldShow = localStorage.getItem(showOnceKey) !== 'true'
    setShow(shouldShow)
    if (shouldShow) {
      setTimeout(() => {
        setShowBanner(true)
        localStorage.setItem(showOnceKey, true)
        setTimeout(() => {
          setShowBanner(false)
        }, 5000)
      }, 5000)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return show && leftIcon && title && urlAlias ? (
    <Container showBanner={showBanner}>
      <Wrapper href={urlAlias}>
        <StyledLeftIcon leftIcon={leftIcon} />
        <StyledBodyWrapper>
          <StyledKnowledgeIconWrapper>
            <StyledScmpFlagWhiteOnly />
            <StyledKnowledgeLogotype />
          </StyledKnowledgeIconWrapper>
          <StyledTitle>{title}</StyledTitle>
          <StyledLeanMore href={urlAlias}>LEARN MORE</StyledLeanMore>
        </StyledBodyWrapper>
        <StyledRightArrowButton>
          <StyledSvgArrowRight />
        </StyledRightArrowButton>
      </Wrapper>
    </Container>
  ) : (
    <></>
  )
}

export default ModalKnowledge
