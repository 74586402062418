import importAll from 'import-all.macro'
import forEach from 'lodash/forEach'
import get from 'lodash/get'
import map from 'lodash/map'
import merge from 'lodash/merge'
import { createContext, useContext, useReducer } from 'react'

// Please update the reducer counter here when you add new reducers
// reducer counter #8
const combineReducers = (reducerObjects) => {
  const combinedReducers = {}
  forEach(reducerObjects, (reducerObject) => {
    const key = get(reducerObject, 'name', false)
    if (key) {
      const { initialState, reducer } = reducerObject
      const [state, dispatch] = useReducer(reducer, initialState)
      combinedReducers[key] = { state, dispatch }
    }
  })

  return combinedReducers
}

const StoreContext = createContext()

const StoreProvider = ({ children, injection = {} }) => {
  const reducerList = map(importAll.sync('./reducers/*.js'), ({ default: reducer }) => {
    if (get(injection, reducer.name)) {
      reducer.initialState = merge(
        {},
        get(reducer, 'defaultState', {}),
        get(injection, reducer.name, {}),
      )
    }
    return reducer
  })

  const value = combineReducers(reducerList)

  return <StoreContext.Provider value={value}>{children}</StoreContext.Provider>
}

const useStore = () => useContext(StoreContext)

export { StoreProvider, useStore }
