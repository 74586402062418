import assign from 'lodash/assign'
import cloneDeep from 'lodash/cloneDeep'
import get from 'lodash/get'

const name = 'rosettaState'

const defaultState = {
  subscriptionStatus: null,
  meterExpired: false,
  hasInitError: false,
  isShowRosetta: false,
}

const initialState = cloneDeep(defaultState)

// Please re-save storeHandler when you add new reducer
function reducer(state = initialState, { type, payload = {} } = {}) {
  switch (type) {
    case 'setSubscriberPhase': {
      assign(state, { subscriberPhase: get(payload, 'subscriberPhase', null) })
      return {
        ...state,
      }
    }
    case 'setSubscriptionStatus': {
      assign(state, { subscriptionStatus: get(payload, 'subscriptionStatus', null) })
      return {
        ...state,
      }
    }
    case 'setIsShowRosetta': {
      assign(state, { isShowRosetta: get(payload, 'isShowRosetta', true) })
      return {
        ...state,
      }
    }
    case 'setMeterExpired':
      assign(state, { meterExpired: true })
      return {
        ...state,
      }
    case 'setInitError':
      assign(state, { hasInitError: true })
      return {
        ...state,
      }
    default:
      return state
  }
}

export default {
  name,
  initialState,
  defaultState,
  reducer,
}
