import get from 'lodash/get'
import map from 'lodash/map'
import { useEffect, useState } from 'react'
import { useStore } from 'store'
import styled, { css } from 'styled-components'
import { color, remCalc, respondTo } from 'styles/mixins'

import { C3PO_URI } from '../../config/app.config'

const StyledOverlay = styled.div`
  width: ${remCalc(485)};
  height: 100%;
  position: fixed;
  top: 0;
  right: 0;
  z-index: 9999;
  overflow-x: hidden;
  overflow-y: auto;
  transition: all 0.3s ease-in-out;
  background-color: ${color.white};
  box-shadow:
    0 9px 12px 1px rgba(0, 0, 0, 0.14),
    0 3px 16px 2px rgba(0, 0, 0, 0.12),
    0 5px 6px 0 rgba(0, 0, 0, 0.2);
  transform: translateX(calc(100% + 20px));

  ${(props) =>
    props.isVisible &&
    css`
      transform: translateX(0);
    `}

  ${respondTo.smAndDown`
    width: 100%;
  `}
`

const StyledFrameContent = styled.iframe`
  width: 100%;
  height: 100%;
  border: 0;
  padding: 0;
  margin: 0;
  display: block;
`

const extrachParams = () => {
  try {
    const url = new URL(window.location.href)
    const params = url.searchParams
    const commentId = params.get('commentID')
    return commentId
  } catch (e) {
    return false
  }
}

const OverlayComment = ({ className }) => {
  const { overlayState, headerState } = useStore()
  const [pinnedCommentId, setPinnedCommentId] = useState(false)
  const [isVisible, setIsVisible] = useState(false)

  const isShown = get(overlayState, 'state.show', false)
  const headerEntityId = get(headerState, 'state.headerEntity.entityId')

  // Right now only article has comment, so it must be article entity id
  const storyId = headerEntityId || get(overlayState, 'state.options.storyId', false)

  // Only local env has the prefix
  const href = C3PO_URI
    ? `${C3PO_URI}/scmp_comments/popup/${storyId}`
    : `/scmp_comments/popup/${storyId}`
  const query = map(
    [{ key: 'commentID', value: pinnedCommentId }],
    ({ key, value }) => `${key}=${value}`,
  ).join('&')
  const src = query ? `${href}?${query}` : href

  useEffect(() => {
    setPinnedCommentId(extrachParams())
    window.addEventListener(
      'message',
      (e) => {
        if (e.data.event === 'closeCommentWindow') {
          // Handler to close the comment layer
          overlayState.dispatch({ type: 'toogleOverlay' })
        }
      },
      false,
    )
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => {
    setTimeout(() => {
      setIsVisible(isShown)
    }, 100)
  }, [isShown])

  return (
    <>
      {storyId && isShown && (
        <StyledOverlay className={className} isVisible={isVisible}>
          <StyledFrameContent key={`comment-overlay-${storyId}`} src={src}></StyledFrameContent>
        </StyledOverlay>
      )}
    </>
  )
}

export default OverlayComment
