import gql from 'graphql-tag'

// check path by url
const GET_COMMENT_COUNTS = gql`
  query getCounts($filter: CommentCountsFilterInput!) {
    commentCounts(filter: $filter) {
      id
      count
    }
  }
`

module.exports = {
  GET_COMMENT_COUNTS,
}
