import { useEffect } from 'react'
import { useStore } from 'store'

const withCommentLayerLogic = (WrappedComponent) => {
  const WithCommentLayerLogic = (props) => {
    const { storyId } = props
    const { overlayState } = useStore()

    const clickHandler = () => {
      if (overlayState && storyId) {
        overlayState.dispatch({
          type: 'toogleOverlay',
          options: {
            overlayType: 'comment-popup',
            storyId,
          },
        })
      }
    }

    useEffect(() => {
      if (typeof window !== 'undefined' && window.c3po && window.c3po.show === true) {
        window.c3po = { show: false }
        clickHandler()
      }
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    const processedProps = {
      ...props,
      clickHandler,
    }

    return <WrappedComponent {...processedProps} />
  }
  return WithCommentLayerLogic
}

export default withCommentLayerLogic
