import classNames from 'classnames/bind'
import Link from 'components/Link'
import { setVariables } from 'lib/gtm'
import compact from 'lodash/compact'
import filter from 'lodash/filter'
import find from 'lodash/find'
import get from 'lodash/get'
import map from 'lodash/map'
import PropTypes from 'prop-types'
import React, { useState, useCallback, useEffect } from 'react'
import styled from 'styled-components'
import { remCalc, color } from 'styles/mixins'

const Wrapper = styled.div``

const SectionItem = styled.div`
  color: ${color.black};
  font-size: ${remCalc(16)};
  line-height: ${remCalc(19)};
  text-transform: uppercase;
  letter-spacing: 0.89px;

  a {
    letter-spacing: 1px;
    line-height: ${remCalc(22)};
  }
`

const ToggleButton = styled.span`
  font-size: ${remCalc(26)};
  width: 100%;
  text-align: right;
  flex-basis: ${remCalc(16)};
  flex-grow: 1;
`

const MenuPanel = ({
  activeMenu,
  activeSection,
  item,
  sections,
  isNested,
  toggleable = false,
  isHome,
  nestedComponent = false,
  itemOnClick,
  action,
}) => {
  const menuExpanded = !!(item.entityUuid === activeMenu)
  const childSectionItems = isNested ? filter(sections, { depth: 3, parent: item.entityUuid }) : []
  const itemOnClickHandler = (next) => {
    setVariables({
      event: 'GA4_tracking',
      category: 'menu',
      subcategory: 'section',
      action: 'click',
      customized_parameters: {
        destination: item.path,
      },
    })
    if (toggleable) {
      action(item.entityUuid || item.parent)
      itemOnClick(item.entityUuid || item.parent, next)
    } else {
      itemOnClick(item.entityUuid || item.parent, next, true)
    }
  }
  return (
    <div className={classNames('item', { expanded: menuExpanded && !!childSectionItems.length })}>
      <SectionItem className="section-item">
        {!isHome && (
          <Link
            href={{
              pathname: get(item, 'render', '/_render/sectionpage'),
              query: { entityUuid: item.entityUuid },
            }}
            as={item.path}
            routerMiddleware={itemOnClickHandler}
            className={classNames({ active: menuExpanded })}
          >
            {get(item, 'name')}
          </Link>
        )}
        {isHome && (
          <Link
            href={{
              pathname: item.path,
              query: { entityUuid: item.entityUuid },
            }}
            as={item.path}
            routerMiddleware={itemOnClickHandler}
            className={classNames({ active: menuExpanded })}
          >
            {get(item, 'name')}
          </Link>
        )}
        {isNested && toggleable && !!childSectionItems.length && (
          <ToggleButton onClick={() => action(item.entityUuid)}>
            {menuExpanded ? '-' : '+'}
          </ToggleButton>
        )}
      </SectionItem>
      {isNested &&
        (menuExpanded || !toggleable) &&
        nestedComponent &&
        !isHome &&
        !!childSectionItems.length &&
        React.cloneElement(nestedComponent, {
          entityUuid: item.entityUuid,
          section: childSectionItems,
          itemOnClick,
          isNested,
          activeSection,
        })}
    </div>
  )
}

const NavigationItemsDepth2 = ({
  className,
  section,
  sections,
  activeSection,
  itemOnClick,
  isNested,
  nestedComponent,
  toggleable = true,
}) => {
  const activeEntityUuid = get(activeSection, 'depth2.entityUuid', 0)
  const defaultUuid = get(sections, '[0].entityUuid')
  const [activeMenu, setActiveMenu] = useState(activeEntityUuid)
  useEffect(() => {
    return setActiveMenu(activeEntityUuid)
  }, [setActiveMenu, activeEntityUuid])
  const action = useCallback(
    (activeEntityUuid) => {
      setActiveMenu(activeMenu === activeEntityUuid ? 0 : activeEntityUuid)
    },
    [activeMenu],
  )
  const sectionDepth2 = map(
    filter(section, { parent: get(activeSection, 'depth1.entityUuid', defaultUuid) }),
    (item) => {
      item.isActive = !!(
        item.entityUuid === activeEntityUuid ||
        item.entityUuid === get(activeSection, 'depth2.entityUuid')
      )
      return item
    },
  )
  const appendHome =
    isNested && toggleable
      ? {
          parent: get(activeSection, 'depth1.entityUuid'),
          path: get(activeSection, 'depth1.path'),
          isActive: !find(sectionDepth2, { isActive: true }),
          entityUuid: 0,
          name: 'Overview',
          toggleable: false,
          isHome: true,
        }
      : false

  const menuItems = compact([...[appendHome], ...sectionDepth2])
  const RenderItems = map(menuItems, (item) => {
    return (
      <MenuPanel
        key={`section-${get(item, 'parent', '').toString()}-${get(
          item,
          'entityUuid',
          '',
        ).toString()}`}
        sections={sections}
        nestedComponent={isNested && nestedComponent ? nestedComponent : false}
        item={item}
        toggleable={!!(get(item, 'toggleable') || toggleable)}
        activeSection={activeSection}
        isNested={isNested}
        itemOnClick={itemOnClick}
        isHome={get(item, 'isHome', false)}
        action={action}
        activeMenu={activeMenu}
      />
    )
  })

  return <Wrapper className={classNames(className)}>{RenderItems}</Wrapper>
}

NavigationItemsDepth2.propTypes = {
  section: PropTypes.array.isRequired,
  sections: PropTypes.array,
  activeSection: PropTypes.object,
  itemOnClick: PropTypes.func,
  isNested: PropTypes.bool,
  nestedComponent: PropTypes.object,
  toggleable: PropTypes.bool,
}

export default NavigationItemsDepth2
