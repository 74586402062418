import { css } from 'styled-components'

import { GENERAL } from '../../config/app.config'

const media = {
  xxs: `(min-width: ${GENERAL.breakpoints.xxs}px) and (max-width: ${GENERAL.breakpoints.xs - 1}px)`,
  xxsAndDown: `(max-width: ${GENERAL.breakpoints.xs - 1}px)`,
  xxsAndUp: `(min-width: ${GENERAL.breakpoints.xs}px)`,
  xs: `(min-width: ${GENERAL.breakpoints.xs}px) and (max-width: ${GENERAL.breakpoints.sm - 1}px)`,
  xsAndDown: `(max-width: ${GENERAL.breakpoints.xs - 1}px)`,
  xsAndUp: `(min-width: ${GENERAL.breakpoints.xs}px)`,
  sm: `(min-width: ${GENERAL.breakpoints.sm}px) and (max-width: ${GENERAL.breakpoints.md - 1}px)`,
  smAndDown: `(max-width: ${GENERAL.breakpoints.md - 1}px)`,
  smAndUp: `(min-width: ${GENERAL.breakpoints.sm}px)`,
  md: `(min-width: ${GENERAL.breakpoints.md}px) and (max-width: ${GENERAL.breakpoints.lg - 1}px)`,
  mdAndDown: `(max-width: ${GENERAL.breakpoints.lg - 1}px)`,
  mdAndUp: `(min-width: ${GENERAL.breakpoints.md}px)`,
  lg: `(min-width: ${GENERAL.breakpoints.lg}px)`,
}

const respondTo = Object.keys(media).reduce((accumulator, label) => {
  accumulator[label] = (...args) => css`
    @media ${media[label]} {
      ${css(...args)}
    }
  `
  return accumulator
}, {})

export default respondTo
