import { useQuery } from '@apollo/client'
import { GET_COMMENT_COUNTS } from 'apollo/queries/c3po-middleware'
import get from 'lodash/get'
import map from 'lodash/map'

const useFetchCommentCounts = ({ storyIds = [], ssr = false }) => {
  const queryResult = useQuery(GET_COMMENT_COUNTS, {
    context: {
      ...{ clientName: 'c3po-middleware' },
    },
    variables: {
      filter: {
        storyIds,
      },
    },
    notifyOnNetworkStatusChange: true,
    ssr,
  })
  return map(get(queryResult, 'data.commentCounts', []), 'count')
}

export default useFetchCommentCounts
