import { useQuery } from '@apollo/client'
import { GET_SECTION_BY_ENTITY_UUID } from 'apollo/queries/cs'
import get from 'lodash/get'

const useFetchSection = (entityUuid) => {
  const queryResult = useQuery(GET_SECTION_BY_ENTITY_UUID, {
    variables: { entityUuid },
    notifyOnNetworkStatusChange: true,
    ssr: true,
  })
  return get(queryResult, 'data.section', {})
}

export default useFetchSection
