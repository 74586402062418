import startsWith from 'lodash/startsWith'

import { SITE_URL, AMP_DOMAIN } from '../../config/app.config'

function fullUrl(relativeUrl) {
  return startsWith(relativeUrl, 'http')
    ? relativeUrl
    : `${SITE_URL}${startsWith(relativeUrl, '/') ? relativeUrl : `/${relativeUrl}`}`
}

function ampUrl(relativeUrl) {
  return `${AMP_DOMAIN}${startsWith(relativeUrl, '/') ? relativeUrl : `/${relativeUrl}`}`
}
export { fullUrl, ampUrl }
