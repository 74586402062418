import { setVariables } from 'lib/gtm'
import get from 'lodash/get'
import { useEffect, useState } from 'react'
import { useInView } from 'react-intersection-observer'
import { useStore } from 'store'
import styled from 'styled-components'
import { remCalc, fontFamily, color, respondTo } from 'styles/mixins'
import { isSubscriber, getSubscribeUrl } from 'utils/rosetta'

const StyledWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  ${respondTo.smAndDown`
    margin-block: ${remCalc(20)};
  `}
`

const StyledRow = styled.div`
  display: flex;
  padding: ${remCalc(18)};
  ${respondTo.smAndDown`
    padding-top: ${remCalc(16)};
    justify-content: center;
  `}
`

const StyleSubscribeText = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  font-family: ${fontFamily.gothic};
  font-size: ${remCalc(18)};
  font-weight: 700;
  line-height: 120%;
  color: #ffffff;
  margin: 0;
  ${respondTo.smAndDown`
    text-align: left;
    max-width: ${remCalc(250)};
  `}
`

const StyleSubscribeButton = styled.a`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 16px 48px;
  text-decoration: none;

  position: static;
  width: 106px;
  height: 40px;
  right: 0px;
  top: 0px;

  background: #fcff79;
  border: 2px solid #000000;
  box-sizing: border-box;
  box-shadow: 0px 4px 0px #000000;
  border-radius: 4px;

  flex: none;
  order: 1;
  flex-grow: 0;
  margin: 0px 16px;

  font-family: ${fontFamily.roboto};
  font-weight: bold;
  font-size: ${remCalc(14)};
  line-height: ${remCalc(16)};
  text-align: center;
  text-transform: uppercase;
  color: #000000;
`

const StyledDivider = styled.div`
  display: block;
  background-color: ${color.lightGray};
  width: 100%;
  height: ${remCalc(1)};
  ${respondTo.smAndDown`
    display: none;
  `}
`

const FooterSubscribe = () => {
  const { rosettaState } = useStore()
  const subscriptionStatus = get(rosettaState, 'state.subscriptionStatus', null)
  const isShowRosetta = get(rosettaState, 'state.isShowRosetta', null)
  const subscriberPhase = get(rosettaState, 'state.subscriberPhase', null)

  const [showSubscribe, setShowSubscribe] = useState(undefined)

  const [isImpressionEventTriggered, setIsImpressionEventTriggered] = useState(false)
  const { inView, ref } = useInView()

  useEffect(() => {
    if (isImpressionEventTriggered || !inView || subscriberPhase === null) {
      return
    }

    setVariables({
      event: 'tracking',
      tracking: {
        category: 'Subscription Entry Point',
        action: 'Subscription Entry Point/Footer/Impression',
        label: window.location.href,
      },
    })

    setVariables({
      event: 'GA4_tracking',
      action: 'imp',
      category: 'subs',
      subcategory: 'entry_point',
      customized_parameters: {
        call_to_action: 'subscribe',
        paywall_status: 'hard',
        subscriber_phase: subscriberPhase,
        trigger_point: 'footer',
      },
    })

    setIsImpressionEventTriggered(true)
  }, [inView, isImpressionEventTriggered, subscriberPhase])

  const clickButton = () => {
    setVariables({
      event: 'tracking',
      tracking: {
        category: 'Subscription Entry Point',
        action: 'Subscription Entry Point/Footer',
        label: window.location.href,
      },
    })

    setVariables({
      event: 'GA4_tracking',
      action: 'click',
      category: 'subs',
      subcategory: 'entry_point',
      customized_parameters: {
        call_to_action: 'subscribe',
        paywall_status: 'hard',
        subscriber_phase: subscriberPhase,
        trigger_point: 'footer',
      },
    })
  }

  useEffect(() => {
    // wait until subscriptionStatus is not null
    if (subscriptionStatus !== null && isShowRosetta !== null) {
      if (isSubscriber(subscriptionStatus) === true || !isShowRosetta) {
        setShowSubscribe(false)
      } else {
        setShowSubscribe(true)
      }
    }
  }, [subscriptionStatus, isShowRosetta])

  return (
    showSubscribe === true && (
      <StyledWrapper ref={ref}>
        <StyledDivider />

        <StyledRow>
          <StyleSubscribeText>Subscribe to YP to access our great content</StyleSubscribeText>
          <StyleSubscribeButton href={getSubscribeUrl()} onClick={clickButton}>
            Subscribe
          </StyleSubscribeButton>
        </StyledRow>
      </StyledWrapper>
    )
  )
}

export default FooterSubscribe
