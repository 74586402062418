import { Grid, Row, Col } from '@product/react-styled-flexboxgrid'
import FooterBottom from 'components/Footer/FooterBottom'
import FooterColumn from 'components/Footer/FooterColumn'
import FooterColumnHeading from 'components/Footer/FooterColumnHeading'
import FooterSubscribe from 'components/Footer/FooterSubscribe'
import FooterTopics from 'components/Footer/FooterTopics'
import EpaperButton from 'components/Header/EpaperButton'
import { setVariables } from 'lib/gtm'
import filter from 'lodash/filter'
import get from 'lodash/get'
import map from 'lodash/map'
import remove from 'lodash/remove'
import slice from 'lodash/slice'
import startCase from 'lodash/startCase'
import toLower from 'lodash/toLower'
import { useStore } from 'store'
import styled from 'styled-components'
import { remCalc, fontFamily, noGutter, color, respondTo } from 'styles/mixins'
import { getActiveSection } from 'utils/section'

const StyledFooterColumnHeading = styled(FooterColumnHeading)`
  margin-bottom: ${remCalc(25)};
  ${respondTo.mdAndUp`
    margin-bottom: ${remCalc(26)};
  `}
`

const StyledGrid = styled(Grid)`
  background-color: ${color.black};
  color: ${color.white};
  font-family: ${fontFamily.roboto};
  padding: ${remCalc(48, 0, 32)};
  box-shadow: 0 50vh 0 50vh ${color.black};
  ${respondTo.smAndDown`
    padding: ${remCalc(39, 12, 32)};
  `}
`

const StyledRow = styled(Row)`
  ${noGutter}
`

const StyledFooterListRow = styled(StyledRow)`
  margin-bottom: ${remCalc(57)};
  ${respondTo.smAndDown`
    margin-bottom: 0;
    padding-bottom: ${remCalc(7)};
  `}
`

const StyledEpaperButton = styled(EpaperButton)`
  filter: invert(1);
  ${respondTo.smAndDown`
    margin: auto;
  `}
`

const Footer = () => {
  const { menuState, footerState } = useStore()

  const shouldShowFooter = get(footerState, 'state.show', false)

  const sections = get(menuState, 'state.sections', [])

  const depthOneSections = filter(sections, { depth: 1 })

  const topFourDepthOneSections = slice(depthOneSections, 0, 4)

  const staticPageItems = [
    {
      text: 'About Us',
      href: '/yp/about-us',
    },
    {
      text: 'Contact Us',
      href: '/yp/contact-us',
    },
    {
      text: 'Write for Us',
      href: '/yp/report/latest-reports/article/3068956/guide-how-write-young-post',
    },
    {
      text: 'Privacy Policy',
      href: 'https://scmp.com/privacy-policy',
    },
    {
      text: 'Terms & Conditions',
      href: 'https://scmp.com/terms-conditions',
    },
  ]

  const trendingTopicsItems = [
    {
      text: 'HKDSE - Hong Kong Diploma of Secondary Education',
      href: '/yp/topics/hkdse',
    },
    {
      text: 'Health and fitness',
      href: '/yp/topics/health-and-fitness',
    },
    {
      text: 'Environmental',
      href: '/yp/topics/environment',
    },
    {
      text: 'Living',
      href: '/yp/topics/living',
    },
    {
      text: 'Relationships',
      href: '/yp/topics/relationships',
    },
    {
      text: 'Student life',
      href: '/yp/topics/student-life',
    },
    {
      text: 'Careers',
      href: '/yp/topics/careers',
    },
    {
      text: 'English exercises',
      href: '/yp/topics/english-exercises',
    },
    {
      text: 'Video games',
      href: '/yp/topics/video-games',
    },
    {
      text: 'K-pop',
      href: '/yp/topics/k-pop',
    },
    {
      text: 'Mental health',
      href: '/yp/topics/mental-health',
    },
    {
      text: 'Celebrities',
      href: '/yp/topics/celebrities',
    },
    {
      text: 'Media literacy',
      href: '/yp/topics/media-literacy',
    },
  ]

  const sectionItemOnClick = (entityUuid, callback) => {
    const SectionGroup = remove(
      map(getActiveSection(entityUuid), (item) => startCase(toLower(get(item, 'name')))),
      (item) => !!item,
    ).join('/')
    setVariables({
      event: 'tracking',
      tracking: {
        category: 'Footer',
        action: 'Footer/Click',
        label: `Footer/Click/${SectionGroup}`,
      },
      eventCallback: callback,
    })
  }

  const itemOnClick = (item, callback) => {
    setVariables({
      event: 'tracking',
      tracking: {
        category: 'Footer',
        action: 'Footer/Click',
        label: `Footer/Click/${item}`,
      },
      eventCallback: callback,
    })
  }

  const footerColumns = map(topFourDepthOneSections, ({ entityUuid, name, path }, index) => {
    const depthTwoSections = filter(sections, { parent: entityUuid })
    const items = map(
      depthTwoSections,
      ({ name: subSectionName, path: subSectionPath, entityUuid }) => ({
        text: subSectionName,
        href: subSectionPath,
        entityUuid,
      }),
    )
    return (
      <FooterColumn
        isLast={index === topFourDepthOneSections.length - 1}
        headerItem={{ text: name, href: path, entityUuid }}
        items={items}
        itemOnClick={sectionItemOnClick}
      />
    )
  })

  return (
    shouldShowFooter && (
      <StyledGrid>
        <StyledFooterListRow>
          <Col xs={16} sm={16} md={2} lg={2} xsOffset={0} smOffset={0} mdOffset={0} lgOffset={2}>
            {footerColumns[0]}
          </Col>
          <Col xs={16} sm={16} md={2} lg={2} xsOffset={0} smOffset={0} mdOffset={0} lgOffset={0}>
            {footerColumns[1]}
          </Col>
          <Col xs={16} sm={16} md={2} lg={2} xsOffset={0} smOffset={0} mdOffset={0} lgOffset={0}>
            {footerColumns[2]}
          </Col>
          <Col xs={16} sm={16} md={2} lg={2} xsOffset={0} smOffset={0} mdOffset={0} lgOffset={0}>
            {footerColumns[3]}
          </Col>
          <Col
            xs={false}
            sm={false}
            md={5}
            lg={3}
            xsOffset={0}
            smOffset={0}
            mdOffset={0}
            lgOffset={0}
          >
            <StyledFooterColumnHeading headerItem={{ text: 'trending topics', href: false }} />
            <FooterTopics items={trendingTopicsItems} itemOnClick={itemOnClick} />
          </Col>
          <Col xs={16} sm={16} md={3} lg={2} xsOffset={0} smOffset={0} mdOffset={0} lgOffset={0}>
            <StyledEpaperButton />
          </Col>
        </StyledFooterListRow>
        <StyledRow>
          <Col xs={16} md={16} lg={12} mdOffset={0} lgOffset={2}>
            <FooterSubscribe />
          </Col>
        </StyledRow>
        <StyledRow>
          <Col xs={16} md={16} lg={12} mdOffset={0} lgOffset={2}>
            <FooterBottom items={staticPageItems} itemOnClick={itemOnClick} />
          </Col>
        </StyledRow>
      </StyledGrid>
    )
  )
}

export default Footer
