import gql from 'graphql-tag'

const APPLICATION_FRAGMENT = gql`
  fragment applicationFragment on Application {
    entityId
    entityUuid
    name
  }
`
// Apollo requires some definition for fragment types
const INTROSPECTION_FRAGMENT_POSSIBLE_TYPES = [
  'Author',
  'Cuisine',
  'Diet',
  'Difficulty',
  'MainIngredient',
  'MealType',
  'Recipe',
  'Section',
  'Topic',
  'Video',
]

const ARTICLE_FRAGMENT_LITE = gql`
  fragment articleFragmentLite on Article {
    entityId
    entityUuid
    urlAlias
    socialHeadline
    shortURL
    headline
    subHeadline
    summary
    contentLock
    publishedDate
    updatedDate
    sponsorType
    textAuthorByline
    ypTopics {
      entityId
      name
      urlAlias
      entityUuid
      sponsor {
        name
      }
    }
    types {
      entityUuid
      name
    }
    authors {
      name
      urlAlias
      types
      bio
      image(filter: { type: AUTHOR_IMAGE }) {
        url
        size_500x500: style(filter: { style: "500x500" }) {
          url
        }
      }
      socialLinks {
        url
        class
      }
    }
    images {
      title
      url
      width
      height
      type
      landscape: style(filter: { style: "landscape" }) {
        url
      }
      portrait: style(filter: { style: "portrait" }) {
        url
      }
      size_600x400: style(filter: { style: "crop_600x400" }) {
        url
      }
      size_660x385: style(filter: { style: "660x385" }) {
        url
      }
      og_image: style(filter: { style: "og_image_young_post" }) {
        url
        style
      }
      square: style(filter: { style: "square" }) {
        url
      }
    }
    sections {
      name
      entityId
      urlAlias
      entityUuid
    }
  }
`

const ARTICLE_FRAGMENT = gql`
  fragment articleFragment on Article {
    entityId
    entityUuid
    urlAlias
    socialHeadline
    shortURL
    advertZone(version: 2)
    headline
    subHeadline
    summary
    contentLock
    publishedDate
    updatedDate
    printHeadline
    sponsorType
    textAuthorByline
    types {
      entityId
      entityUuid
      name
    }
    moreOnThisArticles {
      ...articleFragmentLite
    }
    authors {
      name
      urlAlias
      types
      bio
      image(filter: { type: AUTHOR_IMAGE }) {
        url
        size_500x500: style(filter: { style: "500x500" }) {
          url
        }
      }
      socialLinks {
        url
        class
      }
    }
    ypTopics {
      entityId
      name
      urlAlias
      entityUuid
      sponsor {
        name
      }
    }
    images {
      title
      url
      width
      height
      type
      landscape: style(filter: { style: "landscape" }) {
        url
      }
      portrait: style(filter: { style: "portrait" }) {
        url
      }
      size_600x400: style(filter: { style: "crop_600x400" }) {
        url
      }
      og_image: style(filter: { style: "og_image_young_post" }) {
        url
        style
      }
      size_660x385: style(filter: { style: "660x385" }) {
        url
      }
      square: style(filter: { style: "square" }) {
        url
      }
    }
    sections {
      name
      entityId
      urlAlias
      entityId
      entityUuid
    }
    types {
      entityId
      entityUuid
      name
    }
    paywallTypes {
      entityId
      entityUuid
      name
    }
    glossary {
      term
      translation
      description
    }
    body(
      customContents: [
        { row: 9, type: "reading-50-percent-completion-tracker" }
        { row: 9999, type: "reading-100-percent-completion-tracker" }
      ]
    )
  }
  ${ARTICLE_FRAGMENT_LITE}
`

export {
  APPLICATION_FRAGMENT,
  ARTICLE_FRAGMENT,
  ARTICLE_FRAGMENT_LITE,
  INTROSPECTION_FRAGMENT_POSSIBLE_TYPES,
}
