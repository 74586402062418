import cloneDeep from 'lodash/assign'
import assign from 'lodash/assign'
import get from 'lodash/get'

const name = 'headerState'

const defaultState = {
  menuExpanded: false,
  headerTitle: '',
  headerEntity: {
    entityId: null,
    title: '',
  },
  scrollableWrapperMode: false,
}

const initialState = cloneDeep(defaultState)

// Please re-save storeHandler when you add new reducer
function reducer(state = initialState, { type, payload = {} } = {}) {
  const { headerEntity } = payload
  switch (type) {
    case 'CloseMenu':
      assign(state, { menuExpanded: false })
      return {
        ...state,
      }
    case 'ToggleMenu':
      assign(state, { menuExpanded: !state.menuExpanded })
      return {
        ...state,
      }
    case 'setHeaderTitle':
      assign(state, { headerTitle: get(payload, 'value') })
      return {
        ...state,
      }
    case 'setHeaderEntity':
      return {
        ...state,
        headerEntity,
      }
    case 'setScrollableWrapperMode':
      assign(state, { scrollableWrapperMode: get(payload, 'value') })
      return {
        ...state,
      }
    default:
      return state
  }
}

export default {
  name,
  initialState,
  defaultState,
  reducer,
}
