module.exports = {
  ACCOUNT_DEFAULT_AUTH_CALLBACK_URL:
    process.env.NEXT_PUBLIC_ACCOUNT_DEFAULT_AUTH_CALLBACK_URL ||
    'https://staging-scmp-pwa.product-web.dev-2.scmp.tech/centralize/signin?nonce=',
  ACCOUNT_DEFAULT_REGISTER_CALLBACK_URL:
    process.env.NEXT_PUBLIC_ACCOUNT_DEFAULT_REGISTER_CALLBACK_URL ||
    'https://staging-scmp-pwa.product-web.dev-2.scmp.tech/centralize/register?email=',
  ACCOUNT_MIXPANEL_TOKEN:
    process.env.NEXT_PUBLIC_ACCOUNT_MIXPANEL_TOKEN || '3df890e68e74400967bcce74686c7f16',
  AMP_DOMAIN:
    process.env.NEXT_PUBLIC_AMP_DOMAIN || 'https://amp.staging-scmp-d8.product-web.dev-2.scmp.tech',
  API_GW: process.env.NEXT_PUBLIC_API_GW || 'https://staging-apigw.product-web.dev-2.scmp.tech',
  APP_PREFIX: process.env.NEXT_PUBLIC_APP_PREFIX || '/yp',
  C3PO_API_URI: process.env.NEXT_PUBLIC_C3PO_API_URI,
  C3PO_URI: process.env.NEXT_PUBLIC_C3PO_URI,
  CENTRALIZE_LOGIN_HOST:
    process.env.NEXT_PUBLIC_CENTRALIZE_LOGIN_HOST ||
    'staging-scmp-account.product-web.dev-2.scmp.tech',
  CENTRALIZE_LOGIN_PROTOCOL: process.env.NEXT_PUBLIC_CENTRALIZE_LOGIN_PROTOCOL || 'https',
  CONTENT_API_KEY: process.env.NEXT_PUBLIC_CONTENT_API_KEY || '1DHVoK6d09e9Usm4Cvujj1vParXGv3Lt',
  CONTENT_API_URI:
    process.env.NEXT_PUBLIC_CONTENT_API_URI ||
    'https://staging-apigw.product-web.dev-2.scmp.tech/content-delivery/v1/graphql',
  D8_API_URI:
    process.env.NEXT_PUBLIC_D8_API_URI || 'https://staging-apigw.product-web.dev-2.scmp.tech/d8/v1',
  ELASTIC_SEARCH_APIKEY: process.env.NEXT_PUBLIC_ELASTIC_SEARCH_APIKEY,
  ELASTIC_SEARCH_HOST: process.env.NEXT_PUBLIC_ELASTIC_SEARCH_HOST,
  ELASTIC_SEARCH_PROTOCAL: process.env.NEXT_PUBLIC_ELASTIC_SEARCH_PROTOCAL,
  ENABLE_SENTRY: process.env.NEXT_PUBLIC_ENABLE_SENTRY,
  FB_APP_ID: process.env.NEXT_PUBLIC_FB_APP_ID || '282931841805811',
  FB_PAGE_ID: process.env.NEXT_PUBLIC_FB_PAGE_ID || 116313905072708, // https://www.facebook.com/youngposthk/
  GA_PROFILE_ID: process.env.NEXT_PUBLIC_GA_PROFILE_ID || 'UA-6891676-56',
  GOOGLE_CLIENT_ID:
    process.env.NEXT_PUBLIC_GOOGLE_CLIENT_ID ||
    '399013205570-169fssvc94c07pijogbv05knmkl7ap76.apps.googleusercontent.com',
  GPT_AD_NETWORK_ID: process.env.NEXT_PUBLIC_GPT_AD_NETWORK_ID,
  GPT_AD_SITE_NAME: process.env.NEXT_PUBLIC_GPT_AD_SITE_NAME,
  GTM_ID: process.env.NEXT_PUBLIC_GTM_ID || 'GTM-N3FDTB9', // TODO
  NEWSLETTER_ID: 505187,
  PIANO_AID: process.env.NEXT_PUBLIC_PIANO_AID || '',
  PIANO_API_URI: process.env.NEXT_PUBLIC_PIANO_API_URI || '',
  PIANO_ENV: process.env.NEXT_PUBLIC_PIANO_ENV || '',
  PIANO_ORIGIN: process.env.NEXT_PUBLIC_PIANO_ORIGIN || '',
  PWA_DOMAIN:
    process.env.NEXT_PUBLIC_PWA_DOMAIN || 'staging-young-post.product-web.dev-2.scmp.tech',
  PWA_PROTOCOL: process.env.NEXT_PUBLIC_PWA_PROTOCOL || 'https',
  RECAPTCHA_SITE_KEY:
    process.env.NEXT_PUBLIC_RECAPTCHA_SITE_KEY || '6Lfb87QZAAAAAHADh5nv4kdK5f1eKu87MIByoimj',
  SCMP_MAIN_SITE_DOMAIN: process.env.NEXT_PUBLIC_SCMP_MAIN_SITE_DOMAIN,
  ROSETTA_CONTENTSERVICE_ENVIRONMENT:
    process.env.NEXT_PUBLIC_ROSETTA_CONTENTSERVICE_ENVIRONMENT || 'development',
  SHARE_COUNT_ENDPOINT:
    process.env.NEXT_PUBLIC_SHARE_COUNT_ENDPOINT ||
    'https://staging-apigw.product-web.dev-2.scmp.tech/share-count/v1',
  SITE_URL: process.env.NEXT_PUBLIC_SITE_URL,
  SHOW_DEV_GRID: process.env.NEXT_PUBLIC_SHOW_DEV_GRID === 'true',
  SUBSCRIBE_HOST: process.env.NEXT_PUBLIC_SUBSCRIBE_HOST || '',
  SUBSCRIBE_PROTOCOL: process.env.NEXT_PUBLIC_SUBSCRIBE_PROTOCOL || '',
  TEACHERS_NEWSLETTER_ID: 511490,
  USER_PROFILE_PIC_PREFIX:
    process.env.NEXT_PUBLIC_USER_PROFILE_PIC_PREFIX ||
    'https://staging-scmp-d8.product-web.dev-2.scmp.tech/user/pic/',
  VIDEO_PAGE:
    process.env.NEXT_PUBLIC_VIDEO_PAGE ||
    'https://staging-scmp-multimedia.product-web.dev-2.scmp.tech/video/yp',
  EPAPER_LINK: 'https://www.scmp.com/epaper', // static link, no need to get from env vars
  GENERAL: {
    appPrefix: '/yp',
    baseFontSize: 16,
    $breakpoints: {
      xxs: '0px',
      xs: '375px',
      sm: '768px',
      md: '1024px',
      lg: '1440px',
      xl: '1904px',
    },
    '$max-widths': {
      sm: '100%',
      md: '960px',
      lg: '1176px',
      xl: '1562px',
    },
    breakpoints: {
      xxs: 0,
      xs: 375,
      sm: 768,
      md: 960,
      lg: 1440,
    },
    container: {
      sm: 768,
      md: 'fluid',
      lg: 1384,
    },
    gridSize: 16,
    gutterWidth: 24,
    outerMargin: 0,
  },
}
