import PropTypes from 'prop-types'
import EmailOIcon from 'public/assets/svg/email-o.svg'
import FacebookOIcon from 'public/assets/svg/facebook-o.svg'
import FacebookIcon from 'public/assets/svg/facebook.svg'
import InstagramOIcon from 'public/assets/svg/instagram-o.svg'
import InstagramIcon from 'public/assets/svg/instagram.svg'
import TwitterOIcon from 'public/assets/svg/twitter-o.svg'
import TwitterIcon from 'public/assets/svg/twitter.svg'
import YoutubeIcon from 'public/assets/svg/youtube.svg'

const Buttons = ({ width, height }) => ({
  facebook: {
    href: 'https://www.facebook.com/youngposthk/',
    icon: <FacebookIcon width={width || 11} height={height || 23} />,
    oIcon: <FacebookOIcon width={width || 11} height={height || 23} />,
  },
  twitter: {
    href: 'https://twitter.com/youngposthk?s=20',
    icon: <TwitterIcon width={width || 22} height={height || 18} />,
    oIcon: <TwitterOIcon width={width || 22} height={height || 18} />,
  },
  instagram: {
    href: 'https://www.instagram.com/youngposthk/?hl=en',
    icon: <InstagramIcon width={width || 25} height={height || 24} />,
    oIcon: <InstagramOIcon width={width || 25} height={height || 24} />,
  },
  youtube: {
    href: 'https://www.youtube.com/channel/UC9OTMrlpzZ8bU0P0azBjwOQ',
    icon: <YoutubeIcon width={width || 25} height={height || 17} />,
    oIcon: <></>,
  },
  email: {
    href: '#',
    icon: <></>,
    oIcon: <EmailOIcon width={width || 20} height={height || 16} />,
  },
})

const SocialButton = (props) => {
  const { type, className, isOIcon, href, onClickCallback = () => {} } = props
  const iconType = isOIcon ? 'oIcon' : 'icon'
  const handleClick = () => {
    if (onClickCallback) onClickCallback(type)
  }

  return (
    <a
      target="_blank"
      rel="noopener noreferrer"
      className={className}
      href={href || Buttons(props)[type]['href']}
      onClick={handleClick}
    >
      {Buttons(props)[type][iconType]}
    </a>
  )
}

SocialButton.propTypes = {
  href: PropTypes.string,
  type: PropTypes.string.isRequired,
  onClickCallback: PropTypes.func,
}

export default SocialButton
