import classNames from 'classnames/bind'
import NavigationItemsDepth2 from 'components/Header/children/NavigationItemsDepth2'
import NavigationItemsDepth3 from 'components/Header/children/NavigationItemsDepth3'
import filter from 'lodash/filter'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import { remCalc, color, fontFamily } from 'styles/mixins'

const Wrapper = styled.div`
  padding: ${remCalc(0, 16)};
`

const StyledNavigationItemsDepth2 = styled(NavigationItemsDepth2)`
  position: relative;
  font-family: ${fontFamily.gothic};
  padding: ${remCalc(6, 23)};
  background: ${(props) => props.theme.menuBgColor};
  color: ${color.white};
  font-size: ${remCalc(18)};
  font-weight: 300;
  letter-spacing: ${remCalc(1)};
  line-height: ${remCalc(22)};
  text-transform: uppercase;
  border-radius: ${remCalc(16)};
  > .item {
    padding: ${remCalc(10, 0)};
    border-bottom: solid ${remCalc(1)} ${(props) => props.theme.bgColor};
    &:last-child {
      border: none;
    }
    .section-item {
      display: flex;
      justify-content: space-between;

      a {
        font-size: ${remCalc(18)};
      }
    }
    .active {
      font-weight: bold;
    }
    &.expanded {
      background: ${(props) => props.theme.menuHighlightColor};
      margin: ${remCalc(-1, -24)};
      padding: ${remCalc(20, 25)};
      border-radius: ${remCalc(16)};
      border: none;
    }
  }
`

const StyledNavigationItemsDepth3 = styled(NavigationItemsDepth3)`
  text-align: left;
  text-transform: none;
  padding: ${remCalc(15, 0, 11, 0)};
  font-size: ${remCalc(15)};
  letter-spacing: ${remCalc(0.94)};
  line-height: ${remCalc(18)};
  .item {
    font-weight: normal;
    padding-bottom: ${remCalc(13)};
    margin: 0;
    &:last-child {
      padding-bottom: 0;
    }
    &.active {
      font-weight: bold;
    }
  }
`

const NestedMenu = ({ className, sections, activeSection, entityId, itemOnClick }) => {
  const sectionDepth2 = filter(sections, { depth: 2 })
  return (
    <Wrapper className={classNames(className)}>
      <StyledNavigationItemsDepth2
        isNested={true}
        section={sectionDepth2}
        sections={sections}
        activeSection={activeSection}
        entityId={entityId}
        itemOnClick={itemOnClick}
        nestedComponent={<StyledNavigationItemsDepth3 />}
      />
    </Wrapper>
  )
}

NestedMenu.propTypes = {
  sections: PropTypes.array.isRequired,
  activeSection: PropTypes.object.isRequired,
  entityId: PropTypes.string.isRequired,
  itemOnClick: PropTypes.func.isRequired,
}

export default NestedMenu
