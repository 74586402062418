import classNames from 'classnames/bind'
import Link from 'components/Link'
import { setVariables } from 'lib/gtm'
import get from 'lodash/get'
import map from 'lodash/map'
import PropTypes from 'prop-types'
import { useEffect, useRef } from 'react'
import styled from 'styled-components'
import { remCalc, color, fontFamily } from 'styles/mixins'

const Container = styled.div`
  display: flex;
  justify-content: flex-start;
`

const SectionItem = styled.span`
  color: transparent;
  font-family: ${fontFamily.gothic};
  font-weight: bold;
  text-transform: uppercase;
  -webkit-text-stroke-width: ${remCalc(1)};
  -webkit-text-stroke-color: ${color.black};
  display: block;
  position: relative;
  &:hover,
  &.active {
    color: ${color.black};
    -webkit-text-stroke-color: transparent;
  }
  a {
    color: inherit;
    -webkit-text-stroke-width: inherit;
    line-height: ${remCalc(28)};
  }
`

const NavigationItemsDepth1 = ({
  className,
  section = [],
  activeSection = {},
  itemOnClick = () => {},
}) => {
  const refs = useRef([])
  useEffect(() => {
    const activeIndex = section.findIndex(
      (item) => item.entityUuid === get(activeSection, 'depth1.entityUuid'),
    )
    if (activeIndex !== -1 && refs?.current?.[activeIndex]) {
      refs?.current?.[activeIndex]?.scrollIntoView({ behavior: 'auto', inline: 'center' })
    }
  }, [section, activeSection])
  const renderItems = map(section, (item, index) => {
    const isActive = !!(item.entityUuid === get(activeSection, 'depth1.entityUuid'))
    const itemOnClickHandler = (next) => {
      setVariables({
        event: 'GA4_tracking',
        category: 'menu',
        subcategory: 'section',
        action: 'click',
        customized_parameters: {
          destination: item.path,
        },
      })
      itemOnClick(item.entityUuid, next)
    }
    return (
      <div
        className="item"
        key={`section-${item.entityUuid.toString()}`}
        ref={(el) => (refs.current[index] = el)}
      >
        <SectionItem className={classNames({ active: isActive })}>
          <Link
            href={{
              pathname: item.path,
              query: { entityId: item.entityId, entityUuid: item.entityUuid },
            }}
            as={item.path}
            routerMiddleware={itemOnClickHandler}
          >
            {get(item, 'name')}
          </Link>
        </SectionItem>
      </div>
    )
  })

  return <Container className={className}>{renderItems}</Container>
}

NavigationItemsDepth1.propTypes = {
  section: PropTypes.array.isRequired,
  activeSection: PropTypes.object,
  itemOnClick: PropTypes.func,
}

export default NavigationItemsDepth1
