import Link from 'components/Link'
import { setVariables } from 'lib/gtm'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import { color } from 'styles/mixins'

import LogoSvg from './LogoSvg'

const Wrapper = styled.div`
  width: 100%;
  height: 100%;
  color: ${color.black};
  line-height: 0;
  a {
    text-decoration: none;
    color: inherit;
  }
`

const Logo = ({ hideLabel, className, id = 'generic' }) => {
  const itemOnClickHandler = (next) => {
    setVariables({
      event: 'tracking',
      tracking: {
        category: 'Header',
        action: 'Header/Click',
        label: 'YP (Brand Logo)',
      },
      eventCallback: next,
    })
  }

  return (
    <Wrapper className={className}>
      <Link
        href={{
          pathname: '/_render/home',
          query: {},
        }}
        as={'/yp'}
        routerMiddleware={itemOnClickHandler}
      >
        <a>
          <LogoSvg hideLabel={hideLabel} id={id} />
        </a>
      </Link>
    </Wrapper>
  )
}

Logo.propTypes = {
  hideLabel: PropTypes.bool,
  id: PropTypes.string,
}

export default Logo
